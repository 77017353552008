import React from "react";

import Post1 from "../../assets/image/posts/1.jpg";
import Post2 from "../../assets/image/posts/2.jpg";

export default function Blog() {
  return (
    <>
      <div class="section-posts">
        <div class="container">
          <div class="row">
            <div class="main-title">
              <h2>
                <span>Blog</span>
              </h2>
              <p>
                <span>Title Title Title</span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="box">
                <img src={Post1} alt="@title" class="blog-img" />
                <div class="box-body">
                  <h4>Title 1</h4>
                  <p>
                    asdads asdasd asdads asdasd asdads asdasd asdads asdasd{" "}
                  </p>
                  <p>
                    asdads asdasd asdads asdasd asdads asdasd asdads asdasd{" "}
                  </p>
                  <p>
                    asdads asdasd asdads asdasd asdads asdasd asdads asdasd{" "}
                  </p>
                  <a href="#" class="btn btn-danger btn-main float-end mt-2">
                    View More
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="box">
                <img src={Post2} alt="@title" class="blog-img" />
                <div class="box-body">
                  <h4>Title 2</h4>
                  <p>
                    asdads asdasd asdads asdasd asdads asdasd asdads asdasd{" "}
                  </p>
                  <p>
                    asdads asdasd asdads asdasd asdads asdasd asdads asdasd{" "}
                  </p>
                  <p>
                    asdads asdasd asdads asdasd asdads asdasd asdads asdasd{" "}
                  </p>
                  <a href="#" class="btn btn-danger btn-main mt-2">
                    View More
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="box">
                <img src={Post2} alt="@title" class="blog-img" />
                <div class="box-body">
                  <h4>Title 2</h4>
                  <p>
                    asdads asdasd asdads asdasd asdads asdasd asdads asdasd{" "}
                  </p>
                  <p>
                    asdads asdasd asdads asdasd asdads asdasd asdads asdasd{" "}
                  </p>
                  <p>
                    asdads asdasd asdads asdasd asdads asdasd asdads asdasd{" "}
                  </p>
                  <a href="#" class="btn btn-danger btn-main mt-2">
                    View More
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="box">
                <img src={Post2} alt="@title" class="blog-img" />
                <div class="box-body">
                  <h4>Title 2</h4>
                  <p>
                    asdads asdasd asdads asdasd asdads asdasd asdads asdasd{" "}
                  </p>
                  <p>
                    asdads asdasd asdads asdasd asdads asdasd asdads asdasd{" "}
                  </p>
                  <p>
                    asdads asdasd asdads asdasd asdads asdasd asdads asdasd{" "}
                  </p>
                  <a href="#" class="btn btn-danger btn-main mt-2">
                    View More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-info">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-12 col-12">
              <div class="single-info">
                <i class="fas fa-map"></i>
                <h3>Address</h3>
                <ul>
                  <li>Online.</li>
                  <li>Online.</li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="single-info">
                <i class="fas fa-phone"></i>
                <h3>Call</h3>
                <ul>
                  <li>
                    <a href="tel:+0000000000000000">+(+++) 0000 000 000</a>
                  </li>
                  <li>
                    <a href="tel:+0000000000000000">+(+++) 0000 000 000</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="single-info">
                <i class="fas fa-envelope"></i>
                <h3>Mail</h3>
                <ul>
                  <li>
                    <a href="mailto:test@test.com">test@test.com</a>
                  </li>
                  <li>
                    <a href="mailto:test@test.com">test@test.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
