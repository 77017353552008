import React, { useState } from "react";
import { Link } from "react-router-dom";

import Logo from "../../assets/image/logo_new.png";

export default function Footer() {
  const [email, setemail] = useState("");
  return (
    <footer class="site-footer" style={{ marginTop: "40px" }}>
      {/* <!-- Top section --> */}
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">
              <h5>
                <img
                  src={Logo}
                  style={{ width: "18rem" }}
                  alt=""
                  class="img-fluid"
                />
              </h5>
              <p>
                Trade your Favorite Coins with KoinsTrade’s State-of-the-Art
                Binance Automated Account Management System.
              </p>
              <ul class="social-icons">
                <li>
                  <a
                    href="https://www.facebook.com/Koinstrade-103752525444869"
                    style={{ textDecoration: "none" }}
                  >
                    <i class="fab fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/KoinsTrade"
                    style={{ textDecoration: "none" }}
                  >
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/koinstrade"
                    style={{ textDecoration: "none" }}
                  >
                    <i class="fab fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-md-6 col-sm-12">
              {/* <h5>Support</h5> */}
              <ul>
                <li>
                  <Link
                    to="/about"
                    className="nav-link"
                    activeClassName="nav-link active"
                    aria-current="page"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="nav-link"
                    activeClassName="nav-link active"
                    aria-current="page"
                  >
                    Contact Us
                  </Link>
                </li>
                {/*<li>*/}
                {/*  <a href="#">Our Blog</a>*/}
                {/*</li>*/}
                <li>
                  <Link
                    to="/faq"
                    className="nav-link"
                    activeClassName="nav-link active"
                    aria-current="page"
                  >
                    FAQ
                  </Link>
                </li>
                {/*<li>*/}
                {/*  <a href="#">Packages</a>*/}
                {/*</li>*/}
                <li>
                  <Link
                    to="/pricing"
                    className="nav-link"
                    activeClassName="nav-link active"
                    aria-current="page"
                  >
                    Pricing
                  </Link>
                </li>
              </ul>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-12">
              {/* <h5>Legal</h5> */}
              <ul>
                <li>
                  <a
                    href="https://blog.koinstrade.com/"
                    className="nav-link"
                    target="_blank"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    href="https://skyoption.tech/"
                    className="nav-link"
                    target="_blank"
                  >
                    Our Products
                  </a>
                </li>
                <li>
                  <Link
                    to="/terms"
                    className="nav-link"
                    activeClassName="nav-link active"
                    aria-current="page"
                  >
                    Terms &amp; Conditions
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy"
                    className="nav-link"
                    activeClassName="nav-link active"
                    aria-current="page"
                  >
                    Privacy Policy
                  </Link>
                </li>
                {/*<li>*/}
                {/*  <a href="#">Payment Method</a>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <Link*/}
                {/*    to="/support"*/}
                {/*    className="nav-link"*/}
                {/*    activeClassName="nav-link active"*/}
                {/*    aria-current="page"*/}
                {/*  >*/}
                {/*    Live Support*/}
                {/*  </Link>*/}
                {/*</li>*/}
              </ul>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
              <h5>Subscribe To Our Newsletter</h5>
              <div id="mc_embed_signup" style={{ background: "inherit" }}>
                <form
                  action="https://koinstrade.us5.list-manage.com/subscribe/post?u=97363f7226fecc16258fb2482&id=c54be68606"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  class="validate"
                  target="_blank"
                  novalidate
                >
                  <div id="mc_embed_signup_scroll" class="input-group mb-3">
                    <input
                      type="email"
                      value={email}
                      name="EMAIL"
                      class="email form-control"
                      id="mce-EMAIL"
                      placeholder="email address"
                      onChange={(e) => setemail(e.target.value)}
                      required
                      style={{ marginBottom: "15px" }}
                    />
                    {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                    {/* <div
                      style={{ position: "absolute", left: "-5000" }}
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_97363f7226fecc16258fb2482_c54be68606"
                        tabindex="-1"
                        value=""
                      />
                    </div> */}
                    {/* <div class="optionalParent">
                      <div class="clear foot"> */}
                    <input
                      class="btn btn-danger btn-main float-end mt-2"
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      style={{
                        borderRadius: "5px",
                        border: "none",
                      }}
                      // class="button"
                      // onClick={() => setemail("")}
                    />
                    {/* <p class="brandingLogo"><a href="http://eepurl.com/hMbTKH" title="Mailchimp - email marketing made easy and fun"><img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"> */}
                    {/* </div>
                    </div> */}
                  </div>
                </form>
              </div>
              {/* <form action="#">
                <div class="input-group mb-3">
                  <input
                    name="email"
                    class="form-control"
                    placeholder="Your email address"
                    type="email"
                  />
                </div>
                {/*<div class="input-group msg">*/}
              {/*  <label class="text-success">success</label>*/}
              {/*  <label class="text-danger">failed</label>*/}
              {/*</div>*/}
              {/* <button class="btn btn-danger btn-main float-end mt-2">
                  Subscribe
                </button>
              </form>  */}
            </div>
          </div>
          <div class="inner-content">
            <div class="title">
              <h3 class="text-danger">Risk Disclaimer:</h3>
              <p>
                Trading cryptocurrencies carries a high level of risk and may
                not be suitable for all investors. Before deciding to trade
                cryptocurrency, you should carefully consider your investment
                objectives, level of experience, and risk appetite. The
                possibility exists that you could sustain a loss of some or all
                of your initial investment, and therefore you should not invest
                money that you cannot afford to lose.
              </p>
              {/*<p>*/}
              {/*  The asdads asdasd asdads asdasd asdads asdasd a asdads asdasd*/}
              {/*  asdads asdasd asdads asdasd a asdads asdasd asdads asdasd asdads*/}
              {/*  asdasd a*/}
              {/*</p>*/}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- END Top section --> */}

      {/* <!-- Bottom section --> */}
      <div class="footer-bottom">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-6">
              <p class="text-left">
                © Copyright 2022 <a href="#">KoinsTrade</a>, All rights reserved
              </p>
            </div>

            {/* <div class="col-xs-12 col-sm-6">
                <p class="text-right">Visa or Payment Method</p>
              </div> */}
          </div>
        </div>
      </div>
      {/* <!-- END Bottom section --> */}
    </footer>
  );
}
