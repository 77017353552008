import { combineReducers } from "redux";
// import { userReducer } from './user.reducers';
import authReducer from "./authReducer";
import applicationReducer from "./applicationReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  // userReducer: userReducer,
  application: applicationReducer,
});

export default rootReducer;
