import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { CountryDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-number-input";
import ReCAPTCHA from "react-google-recaptcha";

import { userSignUp, resetSignUpSignInStatus } from "../actions/auth.actions";
import "react-phone-number-input/style.css";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      country: "",
      dob: "",
      phone: "",
      recaptcha: "",
      error: null,
    };
  }

  _handleChange = (event, countryInput) => {
    if (this.props.signUp.error) {
      this.props.resetSignUpSignInStatus();
    }

    if (countryInput) {
      this.setState({
        [countryInput]: event,
        error: null,
      });
    } else {
      const { name, value } = event.target;
      this.setState({
        [name]: value,
        error: null,
      });
    }
  };

  _handleSubmit = () => {
    const {
      first_name,
      last_name,
      email,
      password,
      country,
      dob,
      phone,
      recaptcha,
      error,
    } = this.state;
    this.setState({
      error: null,
    });
    if (
      first_name != "" &&
      last_name != "" &&
      email != "" &&
      password != "" &&
      country != "" &&
      dob != "" &&
      phone != "" &&
      recaptcha &&
      !error
    ) {
      this.props.userSignUp(
        first_name,
        last_name,
        email,
        password,
        country,
        dob,
        phone
      );
    } else {
      this.setState({
        error: "All field inputs are mandatory.",
      });
    }
  };
  render() {
    const {
      first_name,
      last_name,
      email,
      password,
      country,
      dob,
      phone,
      recaptcha,
      error,
    } = this.state;
    const { signUp, setloginPopUp, setsignUpPopUp } = this.props;
    const { status, loader } = signUp;
    const error_response = signUp.error;
    return (
      <div className="modal-body">
        <div className="row g-3">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <input
              value={first_name}
              name="first_name"
              type="text"
              onChange={this._handleChange}
              className="form-control"
              placeholder="First Name"
              aria-label="first_name"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <input
              value={last_name}
              name="last_name"
              type="text"
              onChange={this._handleChange}
              className="form-control"
              placeholder="Last Name"
              aria-label="last_name"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <input
              value={email}
              name="email"
              type="email"
              onChange={this._handleChange}
              className="form-control"
              placeholder="Email"
              aria-label="email"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <input
              value={password}
              name="password"
              type="password"
              onChange={this._handleChange}
              className="form-control"
              placeholder="Password"
              aria-label="password"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <CountryDropdown
              value={country}
              className="form-control"
              defaultOptionLabel="Country"
              aria-label="country"
              onChange={(val) => this._handleChange(val, "country")}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <PhoneInput
              placeholder="Enter phone number"
              international
              className="form-control"
              name="phone"
              aria-label="phone"
              // countryCallingCodeEditable={false}
              // defaultCountry="RU"
              value={phone}
              onChange={(val) => this._handleChange(val, "phone")}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <input
              value={dob}
              name="dob"
              type="date"
              onChange={this._handleChange}
              className="form-control"
              placeholder="Birthday"
              aria-label="Birthday"
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="Terms"
              />
              <label className="form-check-label" htmlFor="Terms">
                Accept All Terms
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="policy"
              />
              <label className="form-check-label" htmlFor="policy">
                Accept All Policies
              </label>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <ReCAPTCHA
              sitekey="6LejQ34cAAAAAPe9TG2gu_bXKgsXYCu5oHabvXXs"
              onChange={(val) => this._handleChange(val, "recaptcha")}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <button
              type="button"
              className="btn btn-primary w-100"
              onClick={() => this._handleSubmit()}
            >
              Sign Up
            </button>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div
              className={
                error
                  ? "alert alert-warning"
                  : error_response
                  ? "alert alert-warning"
                  : ""
              }
            >
              {error ? error : error_response ? error_response : null}
              {status ? (
                <React.Fragment>
                  You have successfully Signed up.You will be redirected.
                  {setTimeout(() =>
                    // this.props.history.push("/signin")
                    // this.props.history.push("/"),
                    {
                      setsignUpPopUp(false);
                      setloginPopUp(true);
                    }, 2500)}
                </React.Fragment>
              ) : null}
            </div>
            <p style={{ textAlign: "center" }}>
              Already Signed In?{" "}
              <Link
                // to="/signin"
                // to="/"
                onClick={() => {
                  setsignUpPopUp(false);
                  setloginPopUp(true);
                }}
              >
                Sign In
              </Link>
            </p>
          </div>
        </div>
      </div>
      // </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

const mapStateToProps = (state) => ({
  signUp: state.auth.signUp,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { userSignUp, resetSignUpSignInStatus })
)(SignUp);
