import React, { useState, useEffect } from "react";

export default function FAQs() {
  useEffect(() => {
    scrollToTop();
    return;
  }, []);

  const scrollToTop = () => {
    document
      .getElementsByClassName("parent-main")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div class="section-posts">
        <div class="container">
          <div class="row">
            <div class="main-title">
              <h2>
                <span>Frequently Asked Question</span>
              </h2>
              {/*<p>*/}
              {/*  <span>Title Title Title</span>*/}
              {/*</p>*/}
            </div>
          </div>
          <div class="accordion accordion-flush" id="ac1">
            <div class="row">
              <CustomAccordian
                title={
                  <span class="color-main fw-bold">
                    <i class="far fa-question-circle"> </i> What is Automated
                    Crypto Copy Trading
                  </span>
                }
                content={
                  <>
                    <div class="accordion-body">
                      <p>Crypto Copy Trading is based on following profitable
                      traders automatically. Professional traders generate
                      profits by providing their trading insights to beginner or
                      inexperienced traders, while investors get the ability to
                      copy trades directly into their account easily to gain and
                        increase revenues in the crypto market.</p>
                    </div>
                    {/*<div class="accordion-body">*/}
                    {/*  body body body body body body body body*/}
                    {/*</div>*/}
                    {/*<div class="accordion-body">*/}
                    {/*  body body body body body body body body*/}
                    {/*</div>*/}
                  </>
                }
              />
              <CustomAccordian
                title={
                  <span class="color-main fw-bold">
                    <i class="far fa-question-circle"> </i> How Crypto Copy
                    Trading works
                  </span>
                }
                content={
                  <>
                    <div class="accordion-body">
                      <p>You can easily copy crypto trading strategy by opening
                      your account with Koinstrade. Watch the tutorial to get
                      your API and Secret Key to connect with it and you are
                        done. Track your account performance !</p>
                    </div>
                    {/*<div class="accordion-body">*/}
                    {/*  body body body body body body body body*/}
                    {/*</div>*/}
                    {/*<div class="accordion-body">*/}
                    {/*  body body body body body body body body*/}
                    {/*</div>*/}
                  </>
                }
              />
              <CustomAccordian
                title={
                  <span class="color-main fw-bold">
                    <i class="far fa-question-circle"> </i> Is it Safe to
                    connect my account with Koinstrade?
                  </span>
                }
                content={
                  <>
                    <div class="accordion-body">
                      <p>At Koinstrade, our system only requires the deal-making
                      access to do our automated copy trading operations. There
                      is no need to select “Enable Withdrawals” in API
                      restrictions. This guarantees that your investment and
                        balance remains under your own complete control.</p>
                    </div>
                    {/*<div class="accordion-body">*/}
                    {/*  body body body body body body body body*/}
                    {/*</div>*/}
                    {/*<div class="accordion-body">*/}
                    {/*  body body body body body body body body*/}
                    {/*</div>*/}
                  </>
                }
              />
              <CustomAccordian
                title={
                  <span class="color-main fw-bold">
                    <i class="far fa-question-circle"> </i> Is Copy Trading good
                    for me?
                  </span>
                }
                content={
                  <>
                    <div class="accordion-body">
                      <p>You can choose copy trading if you don’t have enough time
                      to technically and fundamentally analyze the market and
                      all coins. Also many traders lose money because of
                      emotional trading but still want to take profit,
                        Koinstrade is the perfect solution for them.</p>
                    </div>
                    {/*<div class="accordion-body">*/}
                    {/*  body body body body body body body body*/}
                    {/*</div>*/}
                    {/*<div class="accordion-body">*/}
                    {/*  body body body body body body body body*/}
                    {/*</div>*/}
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section-info">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-12">
              <div className="single-info">
                <i className="fas fa-map"></i>
                <h3>Address</h3>
                <ul>
                  {/* <li>Online.</li> */}
                  <li style={{margin: "-11px"}}>
                    60 Cannon Street, London, EC4N 6NP
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-12">
              <div className="single-info">
                <i className="fas fa-phone"></i>
                <h3>Call</h3>
                <ul>
                  {/* <li>
                    <a href="tel:+0000000000000000">+(+++) 0000 000 000</a>
                  </li> */}
                  <li>
                    <a href="tel:+441133281471">+44 113 328 1471</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-12">
              <div className="single-info">
                <i className="fas fa-envelope"></i>
                <h3>Mail</h3>
                <ul>
                  {/* <li>
                    <a href="mailto:test@test.com">test@test.com</a>
                  </li> */}
                  <li>
                    <a href="info@koinstrade.com">info@koinstrade.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const CustomAccordian = (props) => {
  const [toggleAccordion, settoggleAccordion] = useState(false);
  const { title, content } = props;
  return (
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div class="accordion-item">
        <h2 class="accordion-header" id="head1">
          <button
            class={
              toggleAccordion
                ? "accordion-button"
                : "accordion-button collapsed"
            }
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#tap1"
            aria-expanded="false"
            aria-controls="tap1"
            onClick={() => settoggleAccordion(!toggleAccordion)}
          >
            {title}
          </button>
        </h2>
        <div
          id="tap1"
          class={
            toggleAccordion
              ? "accordion-collapse collapse show"
              : "accordion-collapse collapse"
          }
          aria-labelledby="head1"
          data-bs-parent="#ac1"
        >
          {content}
        </div>
      </div>
    </div>
  );
};
