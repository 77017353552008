import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { url } from "../assets/constants";
import Trade from "./Trade";
import { Logout } from "../actions/auth.actions";

class UpdateEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeEmailStatus: false,
      changeEmailLoader: false,
      otp: "",
      otpLoader: false,
      otpStatus: false,
      otpError: null,
    };
  }

  _changeEmail = () => {
    this.setState({
      changeEmailLoader: true,
      changeEmailStatus: false,
      otp: "",
      otpLoader: false,
      otpStatus: false,
      otpError: null,
      emailLoader: false,
      emailStatus: false,
      emailError: null,
    });
    let end_point = url + "api/v1/auth/update-email-token";

    axios
      .post(end_point, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          this.setState({
            changeEmailStatus: true,
            changeEmailLoader: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        let error_status = err.response.status;
        if (error_status === 403) {
          this.props.Logout();
        }
        this.setState({
          changeEmailLoader: false,
          changeEmailStatus: "error",
        });
      });
  };

  _verifyOTP = () => {
    const { otp } = this.state;
    this.setState({
      otpError: null,
      otpLoader: true,
    });

    if (otp != "") {
      let end_point = url + "api/v1/auth/token-verification";
      let formData = new FormData();
      formData.append("code", otp);
      axios
        .post(end_point, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((res) => {
          // console.log(res);
          this.setState({
            otpStatus: true,
            device: res.data.device,
            otpError: null,
            otpLoader: false,
          });
        })
        .catch((err) => {
          console.log(err);
          let error_status = err.response.status;
          if (error_status === 403) {
            this.props.Logout();
          }
          let error = err.response
            ? err.response.data && err.response.data.error
            : "Something went wrong. Please try again.";
          this.setState({
            otpError: error,
            otpLoader: false,
          });
        });
    } else {
      this.setState({
        otpError: "*OTP field is mandatory.",
        otpLoader: false,
      });
    }
  };

  _onUpdateEmail = () => {
    const { newEmail, device } = this.state;

    if (newEmail == "") {
      this.setState({
        emailError: "*email field is mandatory.",
      });
    } else if (!this.validateEmail(newEmail)) {
      this.setState({
        emailError: "Please enter a valid email id.",
      });
    } else if (newEmail != "" && this.validateEmail(newEmail)) {
      this.setState({ emailLoader: true, emailError: null });
      let formData = new FormData();
      formData.append("email", newEmail);
      formData.append("device", device);

      let end_point = url + "api/v1/auth/update-email";

      axios
        .patch(end_point, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((res) => {
          // console.log(res);
          this.setState({
            emailError: null,
            emailLoader: false,
            emailStatus: true,
          });
          setTimeout(() => {
            this.setState({
              changeEmailLoader: false,
              changeEmailStatus: false,
              device: null,
              emailError: null,
              emailLoader: false,
              emailStatus: false,
              newEmail: "",
              otp: null,
              otpError: null,
              otpLoader: false,
              otpStatus: false,
            });
          }, 3500);
        })
        .catch((err) => {
          console.log(err);
          let error_status = err.response.status;
          if (error_status === 403) {
            this.props.Logout();
          }
          let error = err.response
            ? err.response.data && err.response.data.error
            : "Something went wrong. Please try again.";
          this.setState({
            emailError: error,
            emailLoader: false,
            emailStatus: false,
          });
        });
    }
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  render() {
    const {
      changeEmailStatus,
      changeEmailLoader,
      otp,
      otpError,
      otpStatus,
      otpLoader,
      device,
      newEmail,
      emailError,
      emailLoader,
      emailStatus,
    } = this.state;
    const { user } = this.props;
    const { email } = user;
    return (
      <div className="container">
        <div className="row pt-5">
          <div className="admin-dashboard-trade-details-section">
            <div className="col-lg-12">
              <div className="section-header">
                <div className="page-heading">
                  <h2>
                    Update Email Request <strong>{email}</strong>
                  </h2>
                  <p>click change button to change the email</p>
                </div>
                <div className="right-section">
                  <button
                    className="email-page-button"
                    onClick={() => this._changeEmail()}
                  >
                    {changeEmailLoader ? "requesting..." : "Change Email-Id"}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              {changeEmailStatus === true ? (
                <div className="verify-otp-content">
                  <p>Enter OTP sent on your email address : </p>
                  <input
                    type="text"
                    value={otp}
                    name="otp"
                    onChange={(event) =>
                      this.setState({ [event.target.name]: event.target.value })
                    }
                  />
                  <button
                    className="email-page-button"
                    onClick={() => this._verifyOTP()}
                  >
                    {otpLoader ? "verifying" : "Verify OTP"}
                  </button>
                  <p
                    className={otpStatus ? "signup-success" : "signup-error"}
                    style={{ fontSize: "12px", marginLeft: "10px" }}
                  >
                    {otpError
                      ? otpError
                      : otpStatus
                      ? "Verification Successfull."
                      : null}
                  </p>
                </div>
              ) : changeEmailStatus === "error" ? (
                <div>Something went wrong. Please try again.</div>
              ) : null}
              {device ? (
                <div
                  className="enter-email-content"
                  style={{ marginTop: "20px" }}
                >
                  <p>Enter New Email Id : </p>
                  <input
                    type="email"
                    name="newEmail"
                    onChange={(event) =>
                      this.setState({ [event.target.name]: event.target.value })
                    }
                    value={newEmail}
                  />
                  <button
                    className="email-page-button"
                    onClick={() => this._onUpdateEmail()}
                  >
                    {emailLoader ? "updating" : "Update Email"}
                  </button>
                  <p
                    className={emailStatus ? "signup-success" : "signup-error"}
                    style={{ fontSize: "12px", marginLeft: "10px" }}
                  >
                    {emailError
                      ? emailError
                      : emailStatus
                      ? "Email updated successfully."
                      : null}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { Logout })(UpdateEmail);
