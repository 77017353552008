import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { url } from "../assets/constants";
import { Logout } from "../actions/auth.actions";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      old_password: "",
      new_password: "",
      confirm_password: "",
      error: null,
      status: null,
      loader: false,
    };
  }

  _onChangeHandler = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  _onChangePassword = (old_password, new_password, confirm_password) => {
    if (old_password != "" && new_password != "" && confirm_password != "") {
      this.setState({
        loader: true,
        error: "",
      });

      let end_point = url + "api/v1/auth/update-password";

      let formData = {
        old_password: old_password,
        new_password: new_password,
        confirm_password: confirm_password,
      };

      // let formData = new FormData();
      // formData.append("old_password", old_password);
      // formData.append("new_password", new_password);
      // formData.append("confirm_password", confirm_password);

      axios
        .patch(end_point, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((res) => {
          // console.log(res);

          this.setState({
            loader: false,
            status: true,
            old_password: "",
            new_password: "",
            confirm_password: "",
          });

          setTimeout(
            () =>
              this.setState({
                status: false,
              }),
            3500
          );
        })
        .catch((err) => {
          console.log(err);
          let error_status = err.response.status;
          if (error_status === 403) {
            this.props.Logout();
          }
          let error = err.response
            ? err.response.data && err.response.data.message
            : "Something went wrong. Please try again.";
          // console.log(err.response);
          this.setState({
            loader: false,
            status: false,
            error: error,
          });
          setTimeout(
            () =>
              this.setState({
                error: null,
              }),
            3500
          );
        });
    } else {
      this.setState({
        error: "All fields are mandatory",
      });
    }
  };
  render() {
    const {
      old_password,
      new_password,
      confirm_password,
      error,
      loader,
      status,
    } = this.state;
    return (
      <div className="change-password-page-container">
        <div className="change-password-page-content">
          <div className="change-password-section">
            <div className="page-heading">
              <h2>Change Password</h2>
              <p>the page allow you to change account password</p>
            </div>
            <div className="change-password-input-container">
              <label>Old Password</label>
              <input
                type="password"
                value={old_password}
                name="old_password"
                onChange={this._onChangeHandler}
              />
            </div>
            <div className="change-password-input-container">
              <label>New Password</label>
              <input
                type="password"
                value={new_password}
                name="new_password"
                onChange={this._onChangeHandler}
              />
            </div>
            <div className="change-password-input-container">
              <label>Confirm Password</label>
              <input
                type="password"
                value={confirm_password}
                name="confirm_password"
                onChange={this._onChangeHandler}
              />
            </div>
            <p className={status ? "signup-success" : "signup-error"}>
              {error ? error : status ? "Password Updated Successfully!" : null}
            </p>
            <button
              className="change-password-button"
              onClick={() =>
                this._onChangePassword(
                  old_password,
                  new_password,
                  confirm_password
                )
              }
            >
              {loader ? "changing password" : "Change Password"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { Logout })(ChangePassword);
