import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { Redirect, Link } from "react-router-dom";

import {
  resetSignUpSignInStatus,
  userVerifyKeys,
  Logout,
} from "../actions/auth.actions";

class VerifyKeys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_key: "",
      api_secret: "",
      error: null,
    };
  }

  _handleChange = (event) => {
    const { name, value } = event.target;
    if (this.props.verifyKeys.error) {
      this.props.resetSignUpSignInStatus();
    }

    this.setState({
      [name]: value,
      error: null,
    });
  };

  _handleSubmit = () => {
    const { api_key, api_secret, error } = this.state;
    this.setState({
      error: null,
    });
    if (api_key != "" && api_secret != "" && !error) {
      this.props.userVerifyKeys(api_key, api_secret);
    } else {
      this.setState({
        error: "All field inputs are mandatory.",
      });
    }
  };
  render() {
    const { verifyKeys, signIn, Logout } = this.props;
    const { status, loader } = verifyKeys;
    const { api_key, api_secret, error } = this.state;
    const error_response = verifyKeys.error;

    return !this.props.signIn.status ? (
      <Redirect to="/" />
    ) : (
      <div className="verify-keys-page">
        <div className="verify-page-container">
          <div className="heading">
            Please add your Binance API keys to connect.
          </div>
          <div className="verify-page-content">
            <div>
              <div className="verify-page-input-container">
                <label className="signup-label">API Key </label>
                <input
                  className="signup-input"
                  value={api_key}
                  name="api_key"
                  type="text"
                  onChange={this._handleChange}
                />
              </div>
              <div className="verify-page-input-container">
                <label className="signup-label">API Secret </label>
                <input
                  className="signup-input"
                  value={api_secret}
                  name="api_secret"
                  type="password"
                  onChange={this._handleChange}
                />
              </div>
              <p className={status ? "signup-success" : "signup-error"}>
                {error ? error : error_response ? error_response : null}
              </p>
              <button
                className="signin-button"
                onClick={() => this._handleSubmit()}
              >
                {loader ? "Signing In" : "Sign In"}
              </button>
              {/* <Link to="/">Home</Link> */}
              <button className="refresh-button" onClick={() => Logout()}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  verifyKeys: state.auth.verifyKeys,
  signIn: state.auth.signIn,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { userVerifyKeys, resetSignUpSignInStatus, Logout })
)(VerifyKeys);
