import React, { useEffect } from "react";

export default function Support() {
  useEffect(() => {
    scrollToTop();
    return;
  }, []);
  const scrollToTop = () => {
    document
      .getElementsByClassName("parent-main")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <div class="section-posts">
        <div class="container">
          <div class="row">
            <div class="main-title">
              <h2>
                <span>Live Support</span>
              </h2>
              <p>
                <span>Title Title Title</span>
              </p>
            </div>
          </div>
          <div class="row"></div>
        </div>
      </div>
      <div class="section-info">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-12 col-12">
              <div class="single-info">
                <i class="fas fa-map"></i>
                <h3>Address</h3>
                <ul>
                  <li>Online.</li>
                  <li>Online.</li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="single-info">
                <i class="fas fa-phone"></i>
                <h3>Call</h3>
                <ul>
                  <li>
                    <a href="tel:+0000000000000000">+(+++) 0000 000 000</a>
                  </li>
                  <li>
                    <a href="tel:+0000000000000000">+(+++) 0000 000 000</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="single-info">
                <i class="fas fa-envelope"></i>
                <h3>Mail</h3>
                <ul>
                  <li>
                    <a href="mailto:test@test.com">test@test.com</a>
                  </li>
                  <li>
                    <a href="mailto:test@test.com">test@test.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
