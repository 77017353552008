import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import axios from "axios";
import LineChart from "../../components/LineChart";

import Logo from "../../assets/image/logo_new.png";
import Coin4 from "../../assets/image/coins/4.png";
import Coin5 from "../../assets/image/coins/5.png";
import Coin6 from "../../assets/image/coins/6.png";
import Coin7 from "../../assets/image/coins/7.png";
import Offer1 from "../../assets/image/offer/1.jpg";
import Offer2 from "../../assets/image/offer/2.jpg";
import Offer3 from "../../assets/image/offer/3.jpg";
import Offer4 from "../../assets/image/offer/4.jpg";
import Post1 from "../../assets/image/posts/1.jpg";
import Post2 from "../../assets/image/posts/2.jpg";
import How1 from "../../assets/image/how/1.svg";
import How2 from "../../assets/image/how/2.svg";
import How3 from "../../assets/image/how/3.svg";

import maria from "../../assets/image/maria.png";
import man1 from "../../assets/image/man1.png";
import man2 from "../../assets/image/man2.png";

import { getbalancehistoryGraph } from "../../actions/application.action";
import { url } from "../../assets/constants";

import "../../assets/css/style.css";
import "../../assets/css/responsive.css";
import "../../assets/css/main.css";
import "../../assets/css/footer.css";

function Home(props) {
  const { getbalancehistoryGraph, dashboard } = props;
  const { balanceHistory } = dashboard;
  const [bitcoinPrice, setbitcoinPrice] = useState(49069);
  const [etheriumcoinPrice, setetheriumcoinPrice] = useState(3264);
  const [litecoinPrice, setlitecoinPrice] = useState(181);

  useEffect(() => {
    getbalancehistoryGraph(true);
    getCoinLivePrice();
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    document
      .getElementsByClassName("parent-main")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  const getCoinLivePrice = () => {
    let end_point = url + "binance/coin-live-price";
    axios
      .get(end_point)
      .then((response) => {
        if (response.data.length) {
          setbitcoinPrice(response.data[0]["price"]);
          setetheriumcoinPrice(response.data[1]["price"]);
          setlitecoinPrice(response.data[2]["price"]);
        }
      })
      .catch((err) => console.log(err));
  };

  const _renderLineChart = (dataItems) => {
    // console.log(dataItems);
    const { data, loader, error } = dataItems;

    return loader ? (
      <div className="loader-state">loading balance history...</div>
    ) : error ? (
      <div>{error}</div>
    ) : data && data.length ? (
      <LineChart
        data={data}
        lines={["BNB", "BTC", "BUSD", "ETH", "LTC", "TRX", "USDT", "XRP"]}
        hideAxis={true}
        showTooltip={false}
      />
    ) : (
      <div className="loader-state">No Balance History to display</div>
    );
  };

  return (
    <>
      <div class="section-coins">
        <div class="container">
          <div class="row">
            <div class="main-title">
              <h2>
                <span>Coinpedia</span>
              </h2>
              {/*<p>*/}
              {/*  <span>Title Title Title</span>*/}
              {/*</p>*/}
            </div>
          </div>
          <div class="row justify-content-md-center">
            <div class="col-lg-2 col-md-6 col-sm-12 right-border">
              <div class="icon">
                <a
                  href="https://blog.koinstrade.com/bitcoin-btc"
                  target="_blank"
                >
                  <img src={Coin4} alt="" class="img-fluid rounded-circle" />
                </a>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-12 right-border">
              <div class="icon">
                <a
                  href="https://blog.koinstrade.com/ethereum-eth"
                  target="_blank"
                >
                  <img src={Coin5} alt="" class="img-fluid rounded-circle" />
                </a>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-12 right-border">
              <div class="icon">
                <a
                  href="https://blog.koinstrade.com/litecoin-ltc"
                  target="_blank"
                >
                  <img src={Coin6} alt="" class="img-fluid rounded-circle" />
                </a>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-12">
              <div class="icon">
                <a
                  href="https://blog.koinstrade.com/ripple-xrp"
                  target="_blank"
                >
                  <img src={Coin7} alt="" class="img-fluid rounded-circle" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-offers">
        <div class="layout-offers">
          <div class="container">
            <div class="row">
              <div class="main-title">
                <h2>
                  <span class="text-white">Main Coins</span>
                </h2>
                {/*<p>*/}
                {/*  <span class="text-white">Title Title Title</span>*/}
                {/*</p>*/}
              </div>
            </div>
            <div class="row text-center mt-4 pl-3 pr-3">
              <div class="col-sm" id="chart"></div>
            </div>
            <div class="row text-center mt-4 pl-3 pr-3">
              <div class="col-sm">
                <div class="card">
                  <div class="card-body">
                    <div class="h3">Bitcoin (BTC)</div>
                    <div class="h5">(Price in USD)</div>
                    <div id="btc_val" class="h4 kpi-value">
                      ${bitcoinPrice}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="card">
                  <div className="card-body">
                    <div className="h3">Ethereum (ETH)</div>
                    <div className="h5">(Price in USD)</div>
                    <div id="eth_val" className="h4 kpi-value">
                      ${etheriumcoinPrice}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm">
                <div class="card">
                  <div class="card-body">
                    <div class="h3">Litecoin (LTC)</div>
                    <div class="h5">(Price in USD)</div>
                    <div id="ltc_val" class="h4 kpi-value">
                      ${litecoinPrice}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-testimonials">
        <div class="container">
          <div class="row">
            <div
              id="carouselExampleControls"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12 right-border ">
                      <div class="testimonial-item">
                        <p>
                          <i class="fa fa-quote-left quote-icon-left"></i>
                          Thanks for solving A BIG PROBLEM !! As I manage
                          multiple Binance accounts of my clients, Thanks to
                          KoinsTrade, I dont have to log in to each client
                          accounts separately anymore!
                          <i class="fas fa-quote-right quote-icon-right"></i>
                        </p>
                        <img src={man1} class="testimonial-img" alt="" />
                        <h3>Abdullah A.</h3>
                        <h4>Fund Manager</h4>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 right-border ">
                      <div class="testimonial-item">
                        <p>
                          <i class="fa fa-quote-left quote-icon-left"></i>
                          Super Efficient and Lighting Fast Trading
                          <i class="fas fa-quote-right quote-icon-right"></i>
                        </p>
                        <img src={maria} class="testimonial-img" alt="" />
                        <h3>Maria P.</h3>
                        <h4>Crypto Trader</h4>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="testimonial-item">
                        <p>
                          <i class="fa fa-quote-left quote-icon-left"></i>
                          Good Robots! I have chosen two strategies and
                          connected them with my Binance account. I don’t trade
                          anymore, I just watch my account grow :)
                          <i class="fas fa-quote-right quote-icon-right"></i>
                        </p>
                        <img src={man2} class="testimonial-img" alt="" />
                        <h3> Justin T.</h3>
                        <h4> Crypto Investor</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12 right-border ">
                      <div class="testimonial-item">
                        <p>
                          <i class="fa fa-quote-left quote-icon-left"></i>
                          BBBBBBBB AAAAAAAA AAAAAAAA BBBBBBBB BBBBBBBB BBBBBBBB
                          BBBBBBBB BBBBBBBB BBBBBBBB BBBBBBBB BBBBBBBB BBBBBBBB
                          AAAAAAAA BBBBBBBB BBBBBBBB AAAAAAAA
                          <i class="fas fa-quote-right quote-icon-right"></i>
                        </p>
                        <img src={Offer2} class="testimonial-img" alt="" />
                        <h3>user name</h3>
                        <h4>subscription type</h4>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 right-border ">
                      <div class="testimonial-item">
                        <p>
                          <i class="fa fa-quote-left quote-icon-left"></i>
                          BBBBBBBB AAAAAAAA AAAAAAAA BBBBBBBB BBBBBBBB BBBBBBBB
                          BBBBBBBB BBBBBBBB BBBBBBBB BBBBBBBB BBBBBBBB BBBBBBBB
                          AAAAAAAA BBBBBBBB BBBBBBBB AAAAAAAA
                          <i class="fas fa-quote-right quote-icon-right"></i>
                        </p>
                        <img src={Offer2} class="testimonial-img" alt="" />
                        <h3>user name</h3>
                        <h4>subscription type</h4>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="testimonial-item">
                        <p>
                          <i class="fa fa-quote-left quote-icon-left"></i>
                          BBBBBBBB AAAAAAAA AAAAAAAA BBBBBBBB BBBBBBBB BBBBBBBB
                          BBBBBBBB BBBBBBBB BBBBBBBB BBBBBBBB BBBBBBBB BBBBBBBB
                          AAAAAAAA BBBBBBBB BBBBBBBB AAAAAAAA
                          <i class="fas fa-quote-right quote-icon-right"></i>
                        </p>
                        <img src={Offer2} class="testimonial-img" alt="" />
                        <h3>user name</h3>
                        <h4>subscription type</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12 right-border ">
                      <div class="testimonial-item">
                        <p>
                          <i class="fa fa-quote-left quote-icon-left"></i>
                          AAAAAAAA BBBBBBBB BBBBBBBB AAAAAAAA AAAAAAAA AAAAAAAA
                          AAAAAAAA AAAAAAAA AAAAAAAA AAAAAAAA AAAAAAAA AAAAAAAA
                          BBBBBBBB AAAAAAAA AAAAAAAA BBBBBBBB
                          <i class="fas fa-quote-right quote-icon-right"></i>
                        </p>
                        <img src={Offer3} class="testimonial-img" alt="" />
                        <h3>user name</h3>
                        <h4>subscription type</h4>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 right-border ">
                      <div class="testimonial-item">
                        <p>
                          <i class="fa fa-quote-left quote-icon-left"></i>
                          AAAAAAAA BBBBBBBB BBBBBBBB AAAAAAAA AAAAAAAA AAAAAAAA
                          AAAAAAAA AAAAAAAA AAAAAAAA AAAAAAAA AAAAAAAA AAAAAAAA
                          BBBBBBBB AAAAAAAA AAAAAAAA BBBBBBBB
                          <i class="fas fa-quote-right quote-icon-right"></i>
                        </p>
                        <img src={Offer3} class="testimonial-img" alt="" />
                        <h3>user name</h3>
                        <h4>subscription type</h4>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="testimonial-item">
                        <p>
                          <i class="fa fa-quote-left quote-icon-left"></i>
                          AAAAAAAA BBBBBBBB BBBBBBBB AAAAAAAA AAAAAAAA AAAAAAAA
                          AAAAAAAA AAAAAAAA AAAAAAAA AAAAAAAA AAAAAAAA AAAAAAAA
                          BBBBBBBB AAAAAAAA AAAAAAAA BBBBBBBB
                          <i class="fas fa-quote-right quote-icon-right"></i>
                        </p>
                        <img src={Offer3} class="testimonial-img" alt="" />
                        <h3>user name</h3>
                        <h4>subscription type</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="carousel-control-prev d-none"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next d-none"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-products">
        <div class="layout-offers">
          <div class="container">
            <div class="row">
              <div class="main-title">
                <h2>
                  <span class="text-white">Our Products</span>
                </h2>
                {/*<p>*/}
                {/*  <span class="text-white">Title Title Title</span>*/}
                {/*</p>*/}
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-md-6 col-sm-12 text-left">
                <div class="view overlay">
                  <img
                    src={Offer1}
                    alt=""
                    width="300"
                    height="300"
                    class="rounded-circle d-flex"
                  />
                  <div class="mask flex-center blue-gradient-rgba rounded-circle">
                    <div class="row text-center">
                      <div class="col-md-12">
                        <h4 class="text-white">CRYPTO TERMINALS</h4>
                      </div>
                      <div class="col-md-12">
                        <a
                          href="https://skyoption.tech/services/crypto-terminals/"
                          target="_blank"
                          class="btn btn-danger btn-main"
                        >
                          View More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 text-center">
                <div class="view overlay">
                  <img
                    src={Offer2}
                    alt=""
                    width="300"
                    height="300"
                    class="rounded-circle d-flex"
                  />
                  <div class="mask flex-center blue-gradient-rgba rounded-circle">
                    <div class="row text-center">
                      <div class="col-md-12">
                        <h4 class="text-white">TRADING PLATFORMS</h4>
                      </div>
                      <div class="col-md-12">
                        <a
                          href="https://skyoption.tech/services/trading-platforms-mt4-mt5-web-traders/"
                          target="_blank"
                          class="btn btn-danger btn-main"
                        >
                          View More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 text-center">
                <div class="view overlay">
                  <img
                    src={Offer3}
                    alt=""
                    width="300"
                    height="300"
                    class="rounded-circle d-flex"
                  />
                  <div class="mask flex-center blue-gradient-rgba rounded-circle">
                    <div class="row text-center">
                      <div class="col-md-12">
                        <h4 class="text-white">GOLD PLATFORMS</h4>
                      </div>
                      <div class="col-md-12">
                        <a
                          href="https://skyoption.tech/services/gold-platforms/"
                          target="_blank"
                          class="btn btn-danger btn-main"
                        >
                          View More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 text-right">
                <div class="view overlay">
                  <img
                    src={Offer4}
                    alt=""
                    width="300"
                    height="300"
                    class="rounded-circle d-flex"
                  />
                  <div class="mask flex-center blue-gradient-rgba rounded-circle">
                    <div class="row text-center">
                      <div class="col-md-12">
                        <h4 class="text-white">CRM PORTALS</h4>
                      </div>
                      <div class="col-md-12">
                        <a
                          href="https://skyoption.tech/services/crm-portals/"
                          target="_blank"
                          class="btn btn-danger btn-main"
                        >
                          View More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-posts">
        <div class="container">
          <div class="row">
            <div class="main-title">
              <h2>
                <span>How It Works ?</span>
              </h2>
              <p>
                <span>
                  It’s never been easier to invest in crypto, get started!
                </span>
              </p>
            </div>
          </div>
          <div class="row">
            {/* <div class="col-lg-4 col-md-4 col-sm-12"></div> */}
            <div class="col-lg-8 col-md-8 col-sm-12 offset-lg-2">
              <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12 px-5">
                  <img src={How1} alt="" class="img-fluid" />
                  <div class="step">
                    <div class="value">1</div>
                    <p style={{ marginLeft: "10px" }}>Create A New Account</p>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 px-5">
                  <img src={How2} alt="" class="img-fluid" />
                  <div class="step">
                    <div class="value">2</div>
                    <p style={{ marginLeft: "10px" }}>
                      Grab Your API Key & Secret Key, Then Connect
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 px-5">
                  <img src={How3} alt="" class="img-fluid" />
                  <div class="step">
                    <div class="value">3</div>
                    <p style={{ marginLeft: "10px" }}>
                      Track Your Account profit
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-posts">
        <div class="container">
          <div class="row">
            <div class="main-title">
              <h2>
                <span>Performance Chart</span>
              </h2>
            </div>
          </div>
          <div class="row">
            <div className="col-lg-12 py-5">
              <div className="admin-dashboard-trade-details-section">
                {_renderLineChart(balanceHistory)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-posts">
        <div class="container">
          <div class="row">
            <div class="main-title">
              <h2>
                <span>Market Analysis And News</span>
              </h2>
              {/*<p>*/}
              {/*  <span>Title Title Title</span>*/}
              {/*</p>*/}
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="box">
                <img src={Post1} alt="@title" class="img-fluid" />
                <div class="box-body blog">
                  {/* <h4>Blog 1</h4> */}
                  {/*<p>asdads asdasd asdads asdasd asdads asdasd asdads asdasd</p>*/}
                  {/*<p>asdads asdasd asdads asdasd asdads asdasd asdads asdasd</p>*/}
                  {/*<p>asdads asdasd asdads asdasd asdads asdasd asdads asdasd</p>*/}
                  <a
                    href="https://blog.koinstrade.com"
                    class="btn btn-danger btn-main float-end mt-2"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
            {/*<div class="col-lg-6 col-md-6 col-sm-12">*/}
            {/*  <div class="box">*/}
            {/*    <img src={Post2} alt="@title" class="img-fluid" />*/}
            {/*    <div class="box-body">*/}
            {/*      <h4>Blog 2</h4>*/}
            {/*      /!*<p>asdads asdasd asdads asdasd asdads asdasd asdads asdasd</p>*!/*/}
            {/*      /!*<p>asdads asdasd asdads asdasd asdads asdasd asdads asdasd</p>*!/*/}
            {/*      /!*<p>asdads asdasd asdads asdasd asdads asdasd asdads asdasd</p>*!/*/}
            {/*      <a href="#" class="btn btn-danger btn-main mt-2">*/}
            {/*        View More*/}
            {/*      </a>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div class="secTreMaition-info">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-12 col-12">
              <div class="single-info">
                <i class="fas fa-map"></i>
                <h3>Address</h3>
                <ul>
                  <li style={{ margin: "-11px" }}>
                    60 Cannon Street, London, EC4N 6NP
                  </li>
                  {/*<li>Online.</li>*/}
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="single-info">
                <i class="fas fa-phone"></i>
                <h3>Call</h3>
                <ul>
                  <li>
                    <a href="tel:+441133281471">+44 113 328 1471</a>
                  </li>
                  {/*<li>*/}
                  {/*  <a href="tel:+0000000000000000">+(+++) 0000 000 000</a>*/}
                  {/*</li>*/}
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="single-info">
                <i class="fas fa-envelope"></i>
                <h3>Mail</h3>
                <ul>
                  <li>
                    <a href="mailto:info@koinstrade.com">info@koinstrade.com</a>
                  </li>
                  {/*<li>*/}
                  {/*  <a href="mailto:test@test.com">test@test.com</a>*/}
                  {/*</li>*/}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  dashboard: state.application.dashboard,
});

export default connect(mapStateToProps, { getbalancehistoryGraph })(Home);
