import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";

import SignIn from "../../screens/SignIn";
import VerifyKeys from "../../screens/VerifyKeys";
import SideBar from "../../components/SideBar";
import Dashboard from "../../screens/Dashboard";
import ChangePassword from "../../screens/ChangePassword";
import Payments from "../../screens/Payments";
import UpdateEmail from "../../screens/UpdateEmail";
import Trade from "../../screens/Trade";
import Home from "../../screens/website/Home";
import ContactUs from "../../screens/website/ContactUs";
import WebsiteContainer from "../../screens/website/WebsiteContainer";
import AboutUs from "../../screens/website/AboutUs";
import Blog from "../../screens/website/Blog";
import FAQs from "../../screens/website/FAQs";
import Pricing from "../../screens/website/Pricing";
import Payment from "../../screens/website/Payment";
import Privacy from "../../screens/website/Privacy";
import Support from "../../screens/website/Support";
import Terms from "../../screens/website/Terms";

class AppRouter extends Component {
  _checkAuth = (status) => {
    const { tokens, user } = this.props;
    const { access, refresh } = tokens;
    return status && access && refresh ? (
      <LoggedIn userRole={user.user_role} user={user} />
    ) : (
      <LoggedOut />
    );
  };
  render() {
    const { login } = this.props;
    const { status } = login;
    return <React.Fragment>{this._checkAuth(status)}</React.Fragment>;
  }
}

const LoggedOut = (props) => {
  // Script to include crisp chat when not logged in into dashboard
  // and remove crisp chat after successful login.
  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "9706adc7-c092-4d4b-b63f-4551f8fd5514";

    var d = document;
    var s = d.createElement("script");

    s.src = "https://client.crisp.chat/l.js";
    s.async = 1;

    (function () {
      d.getElementsByTagName("head")[0].appendChild(s);
    })();

    return () => {
      var head = document.getElementsByTagName("head")[0];
      var link = head.getElementsByTagName("link");
      var scp = head.getElementsByTagName("script");
      var selected_links = [];
      var selected_scripts = [];

      for (let item of link) {
        if (item.href.includes("https://client.crisp.chat")) {
          selected_links.push(item);
        }
      }
      for (let item of scp) {
        if (item.src.includes("https://client.crisp.chat")) {
          selected_scripts.push(item);
        }
      }
      if (selected_links.length) {
        for (let item of selected_links) {
          document.head.removeChild(item);
        }
      }
      if (selected_scripts.length) {
        for (let item of selected_scripts) {
          document.head.removeChild(item);
        }
      }
    };
  }, []);
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/verify">
          <VerifyKeys />
        </Route>
        <WebsiteContainer>
          <Route exact path="/" component={(p) => <Home {...p} />}>
            {/* <Home  /> */}
          </Route>
          <Route path="/activate">
            <Home />
          </Route>
          <Route path="/contact">
            <ContactUs />
          </Route>
          <Route path="/about">
            <AboutUs />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
          <Route path="/faq">
            <FAQs />
          </Route>
          <Route path="/pricing">
            <Pricing />
          </Route>
          <Route path="/payment">
            <Payment />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/support">
            <Support />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route>
            <RandomRedirect />
          </Route>
        </WebsiteContainer>
      </Switch>
    </BrowserRouter>
  );
};

const LoggedIn = (props) => {
  const { userRole } = props;
  const { user } = props;
  const { active_subscription } = user;
  useEffect(() => {
    if (!localStorage.getItem("reload_allow")) {
      window.location.reload();
    }
    localStorage.setItem("reload_allow", true);
  }, []);
  return (
    <React.Fragment>
      <BrowserRouter>
        <div
          style={{
            display: "flex",
            background: "#f0f2f5",
            flexDirection: "row-reverse",
          }}
        >
          <SideBar userRole={userRole} user={user} />
          <div className="main">
            {/* <Switch> */}
            {
              active_subscription ? (
                <ActiveSubscriptionRoutes userRole={userRole} />
              ) : (
                // (
                //   <>
                //     <Route exact path="/">
                //       <Redirect to="/dashboard" />
                //     </Route>
                //     <Route path="/dashboard">
                //       <Dashboard />
                //     </Route>
                //     <Route path="/change-password">
                //       <ChangePassword />
                //     </Route>
                //     <Route path="/update-email">
                //       <UpdateEmail />
                //     </Route>
                //     {userRole && userRole !== "Admin" && (
                //       <Route path="/payments">
                //         <Payments />
                //       </Route>
                //     )}
                //     {userRole && userRole === "Admin" && (
                //       <Route path="/trade">
                //         <Trade />
                //       </Route>
                //     )}
                //   </>
                // )
                <InActiveSubscriptionRoutes userRole={userRole} />
              )
              // (
              //   <>
              //     <Route exact path="/">
              //       <Redirect to="/payments" />
              //     </Route>
              //     {userRole && userRole !== "Admin" && (
              //       <Route path="/payments">
              //         <Payments />
              //       </Route>
              //     )}
              //   </>
              // )
            }
            {/* <Route>
                <RandomRedirect />
              </Route> */}
            {/* </Switch> */}
          </div>
        </div>
      </BrowserRouter>
    </React.Fragment>
  );
};

const ActiveSubscriptionRoutes = (props) => {
  const { userRole } = props;
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
      <Route path="/dashboard">
        <Dashboard />
      </Route>
      <Route path="/change-password">
        <ChangePassword />
      </Route>
      <Route path="/update-email">
        <UpdateEmail />
      </Route>
      {userRole && userRole !== "Admin" && (
        <Route path="/payments">
          <Payments />
        </Route>
      )}
      {userRole && userRole === "Admin" && (
        <Route path="/trade">
          <Trade />
        </Route>
      )}
      <Route>
        <RandomRedirect />
      </Route>
    </Switch>
  );
};

const InActiveSubscriptionRoutes = (props) => {
  const { userRole } = props;
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/payments" />
      </Route>
      {userRole && userRole !== "Admin" && (
        <Route path="/payments">
          <Payments />
        </Route>
      )}
      <Route>
        <RandomRedirect />
      </Route>
    </Switch>
  );
};

const RandomRedirect = (props) => {
  console.log("random caught");
  return window.location.pathname !== "/activate" && <Redirect to="/" />;
};

const mapStateToProps = (state) => ({
  login: state.auth.login,
  tokens: state.auth.tokens,
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(AppRouter);
