// import "./styles.css";
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

export default function App(props) {
  const { data, lines, hideAxis, showTooltip } = props;
  const colors = [
    "#D65439",
    "#F69222",
    "#F7CA20",
    "#CDEA14",
    "#6CEA14",
    "#14EAA3",
    "#14EAE7",
    "#1482EA",
    "#8C14EA",
    "#DA14EA",
    "#EA147C",
    "#EA1424",
  ];
  return (
    <LineChart
      width={1100}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
      style={{ margin: "auto" }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" tick={{ fontSize: 10 }} />
      <YAxis tick={{ fontSize: 10 }} hide={hideAxis ? hideAxis : false} />
      {showTooltip ? <Tooltip wrapperStyle={{ fontSize: "10px" }} /> : null}
      <Legend wrapperStyle={{ fontSize: "12px" }} />
      <Line
        type="monotone"
        dataKey={"USDT"}
        stroke={"#9e1daf"}
        activeDot={{ r: 8 }}
      />
    </LineChart>
  );
}
