import React, { useEffect } from "react";

import Post1 from "../../assets/image/posts/1.jpg";

export default function AboutUs() {
  useEffect(() => {
    scrollToTop();
    return;
  }, []);
  const scrollToTop = () => {
    document
      .getElementsByClassName("parent-main")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <section class="section-about about-video">
        <div class="container">
          <div class="row">
            <div class="main-title">
              <h2>
                <span>ABOUT US</span>
              </h2>
              <p>
                <span>Koinstrade</span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 video-box align-self-baseline position-relative">
              <img src={Post1} class="img-fluid" alt="" />
              <a
                href="https://www.youtube.com/watch?v=nVHf7Flg3wQ"
                class="glightbox play-btn mb-4"
                data-vbtype="video"
                data-autoplay="true"
                target="_blank"
              ></a>
            </div>
            <div class="col-lg-6 pt-3 pt-lg-0 content">
              <p class="fw-bold">Binance Crypto Trading Made Easy</p>
              <p>
                Trade your Favorite Coins with KoinsTrade’s State-of-the-Art
                Binance Automated Account Management System.
              </p>
              <ul>
                <li>
                  <p>
                    <i class="fas fa-check"></i> KoinsTrade is a one-stop crypto
                    services and technology company that provides comprehensive
                    and automated crypto trading MAM technologies.
                  </p>
                </li>
                <li>
                  <p>
                    <i class="fas fa-check"></i> KoinsTrade is a Binance
                    oriented Cypto Trading solution provider that allows you to
                    start trading and investing in cryptocurrency by connecting
                    your existing Binance account with Powerful Automated Crypto
                    Strategies.
                  </p>
                </li>
                <li>
                  <p>
                    <i class="fas fa-check"></i>
                    Trade crypto with the best crypto signals, a transparent
                    track record, or trade yourself using our advanced MAM
                    trading technology to manage multiple Binance accounts as an
                    Account Manager or connect with fully automated crypto
                    trading bots using Binance.
                  </p>
                </li>
                {/*<li>*/}
                {/*  <i class="fas fa-check"></i> aaaaaaaaaaaaa aaaaaaaaaaaaa*/}
                {/*  aaaaaaaaaaaaaaaaaaaaaaaaaaaa aaaaa*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <i class="fas fa-check"></i> aaaaaaaaaaaaa aaaaaaaaaaaaa*/}
                {/*  aaaaaaaaaaaaaaaaaaaaaaaaa aaaaa*/}
                {/*</li>*/}
              </ul>
              {/*<p>*/}
              {/*  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis*/}
              {/*  aute irure dolor in reprehenderit in voluptate velit esse cillum*/}
              {/*  dolore eu fugiat nulla pariatur. Excepteur sint occaecat*/}
              {/*  cupidatat non proident, sunt in culpa qui officia deserunt*/}
              {/*  mollit anim id est laborum*/}
              {/*</p>*/}
            </div>
          </div>
        </div>
      </section>
      {/*<div class="section-counter">*/}
      {/*  <div class="container">*/}
      {/*    <div class="row">*/}
      {/*      <div class="col-lg-3 col-md-3 col-sm-6">*/}
      {/*        <div class="counter count-box">*/}
      {/*          <span*/}
      {/*            data-purecounter-start="0"*/}
      {/*            data-purecounter-end="100"*/}
      {/*            data-purecounter-duration="10"*/}
      {/*            class="purecounter number"*/}
      {/*          >*/}
      {/*            100*/}
      {/*          </span>*/}
      {/*          <p>client</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div class="col-lg-3 col-md-3 col-sm-6">*/}
      {/*        <div class="counter">*/}
      {/*          <span*/}
      {/*            data-purecounter-start="0"*/}
      {/*            data-purecounter-end="90"*/}
      {/*            data-purecounter-duration="9"*/}
      {/*            class="purecounter number"*/}
      {/*          >*/}
      {/*            90*/}
      {/*          </span>*/}
      {/*          <p>client</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div class="col-lg-3 col-md-3 col-sm-6">*/}
      {/*        <div class="counter">*/}
      {/*          <span*/}
      {/*            data-purecounter-start="0"*/}
      {/*            data-purecounter-end="80"*/}
      {/*            data-purecounter-duration="8"*/}
      {/*            class="purecounter number"*/}
      {/*          >*/}
      {/*            80*/}
      {/*          </span>*/}
      {/*          <p>client</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div class="col-lg-3 col-md-3 col-sm-6">*/}
      {/*        <div class="counter">*/}
      {/*          <span*/}
      {/*            data-purecounter-start="0"*/}
      {/*            data-purecounter-end="70"*/}
      {/*            data-purecounter-duration="7"*/}
      {/*            class="purecounter number"*/}
      {/*          >*/}
      {/*            70*/}
      {/*          </span>*/}
      {/*          <p>client</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div class="section-info">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-12 col-12">
              <div class="single-info">
                <i class="fas fa-map"></i>
                <h3>Address</h3>
                <ul>
                  <li style={{ margin: "-11px" }}>
                    60 Cannon Street, London, EC4N 6NP
                  </li>
                  {/*<li>Online.</li>*/}
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="single-info">
                <i class="fas fa-phone"></i>
                <h3>Call</h3>
                <ul>
                  <li>
                    <a href="tel:+441133281471">+44 113 328 1471</a>
                  </li>
                  {/*<li>*/}
                  {/*  <a href="tel:+0000000000000000">+(+++) 0000 000 000</a>*/}
                  {/*</li>*/}
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="single-info">
                <i class="fas fa-envelope"></i>
                <h3>Mail</h3>
                <ul>
                  <li>
                    <a href="mailto:info@koinstrade.com">info@koinstrade.com</a>
                  </li>
                  {/*<li>*/}
                  {/*  <a href="mailto:test@test.com">test@test.com</a>*/}
                  {/*</li>*/}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
