import React, { useState, useEffect } from "react";

import SignIn from "../SignIn";
import SignUp from "../SignUp";
import Modal from "react-modal";
import Navbar from "./Navbar";

import "../../assets/css/style.css";
import "../../assets/css/responsive.css";
import "../../assets/css/main.css";
import "../../assets/css/footer.css";
import Footer from "./Footer";
import { Redirect } from "react-router-dom";

import { connect } from "react-redux";

import {
  toggleLoginPopUpStatus,
  toggleSignUpPopUpStatus,
} from "../../actions/auth.actions";

function WebsiteContainer(props) {
  const [loginPopUp, setloginPopUp] = useState(false);
  const [signUpPopUp, setsignUpPopUp] = useState(false);
  const [navbarOpen, setnavbarOpen] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const {
    children,
    signUpPopUpStatus,
    loginPopUpStatus,
    toggleLoginPopUpStatus,
    toggleSignUpPopUpStatus,
  } = props;

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    let verification_status = params.get("verification_status");

    if (verification_status) {
      if (verification_status === "true") {
        setVerificationStatus(true);
        // setloginPopUp(true);
        toggleLoginPopUpStatus(true);
      } else if (verification_status === "false") {
        setVerificationStatus(false);
        // setloginPopUp(true);
        toggleLoginPopUpStatus(true);
      } else return <Redirect to="/" />;

      setTimeout(() => {
        setVerificationStatus(null);
      }, 10000);
    } else return <Redirect to="/" />;
  }, []);

  const scrollToTop = () => {
    document
      .getElementsByClassName("parent-main")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  const _handleScroll = (evt) => {
    let target = evt.target;
    let scrolledHeight = target.scrollTop;

    scrolledHeight
      ? document
          .getElementsByClassName("fixed-top")[0]
          .classList.add("scrolled")
      : document
          .getElementsByClassName("fixed-top")[0]
          .classList.remove("scrolled");

    scrolledHeight > 50
      ? (document.getElementsByClassName("scroll-top")[0].style.display =
          "flex")
      : (document.getElementsByClassName("scroll-top")[0].style.display =
          "none");
  };

  const _toggleNavigation = (status) => {
    if (status) {
      document
        .getElementsByClassName("navbar-collapse")[0]
        .classList.add("show");
    } else {
      document
        .getElementsByClassName("navbar-collapse")[0]
        .classList.remove("show");
    }

    setnavbarOpen(status);
  };

  const child = React.Children.map(children, function (child) {
    return React.cloneElement(child, {
      foo: "hello",
    });
  });

  return (
    <div
      className="parent-main"
      style={{ height: "100%", overflow: "auto" }}
      onScroll={(e) => _handleScroll(e)}
    >
      <Navbar
        // setloginPopUp={setloginPopUp}
        setloginPopUp={toggleLoginPopUpStatus}
        _toggleNavigation={_toggleNavigation}
        // setsignUpPopUp={setsignUpPopUp}
        setsignUpPopUp={toggleSignUpPopUpStatus}
        navbarOpen={navbarOpen}
      />
      {child}
      <a href="#" class="scroll-top" onClick={() => scrollToTop()}>
        <i class="fas fa-chevron-up"></i>
      </a>
      <Footer />
      <Modal
        isOpen={loginPopUpStatus}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="login-overlay"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="loginModalLabel">
                Sign In
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={
                  () => toggleLoginPopUpStatus(false)
                  // setloginPopUp(false)
                }
              ></button>
            </div>
            <div class="modal-body">
              <div class="row g-3">
                {/* <div class="col-lg-6 col-md-6 col-sm-12">
                  <a href="#" style={{ textDecoration: "none" }}>
                    <div class="social-auth">
                      <i class="fab fa-facebook"></i> Facebook
                    </div>
                  </a>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <a href="#" style={{ textDecoration: "none" }}>
                    <div class="social-auth">
                      <i class="fab fa-twitter"></i> twitter
                    </div>
                  </a>
                </div> */}
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <SignIn
                    // setloginPopUp={setloginPopUp}
                    setloginPopUp={toggleLoginPopUpStatus}
                    // setsignUpPopUp={setsignUpPopUp}
                    setsignUpPopUp={toggleSignUpPopUpStatus}
                    verification_status={verificationStatus}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={signUpPopUpStatus}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="login-overlay"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="registerModalLabel">
                Sign Up
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={
                  () => toggleSignUpPopUpStatus(false)
                  // setsignUpPopUp(false)
                }
              ></button>
            </div>
            <div class="modal-body">
              <div class="row g-3">
                {/* <div class="col-lg-6 col-md-6 col-sm-12">
                  <a href="#" style={{ textDecoration: "none" }}>
                    <div class="social-auth">
                      <i class="fab fa-facebook"></i> Facebook
                    </div>
                  </a>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <a href="#" style={{ textDecoration: "none" }}>
                    <div class="social-auth">
                      <i class="fab fa-twitter"></i> twitter
                    </div>
                  </a>
                </div> */}
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <SignUp
                    // setloginPopUp={setloginPopUp}
                    setloginPopUp={toggleLoginPopUpStatus}
                    // setsignUpPopUp={setsignUpPopUp}
                    setsignUpPopUp={toggleSignUpPopUpStatus}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const customStyles = {
  content: {
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    padding: 0,
    width: "fit-content",
    background: "transparent",
    border: "none",
  },
};

const mapStateToProps = (state) => ({
  loginPopUpStatus: state.auth.loginPopUpStatus,
  signUpPopUpStatus: state.auth.signUpPopUpStatus,
});

export default connect(mapStateToProps, {
  toggleLoginPopUpStatus,
  toggleSignUpPopUpStatus,
})(WebsiteContainer);
