const initialState = {
  signUp: {
    status: false,
    error: null,
    loader: false,
  },
  signIn: {
    status: false,
    error: null,
    loader: false,
  },
  verifyKeys: {
    status: false,
    error: null,
    loader: false,
  },
  login: {
    status: JSON.parse(localStorage.getItem("login_status"))
      ? JSON.parse(localStorage.getItem("login_status"))
      : false,
  },
  user: JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : {},
  tokens: {
    access: localStorage.getItem("access")
      ? localStorage.getItem("access")
      : null,
    refresh: localStorage.getItem("refresh")
      ? localStorage.getItem("refresh")
      : null,
  },
  loginPopUpStatus: false,
  signUpPopUpStatus: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    // signUp types
    case "SIGNUP_SUCCESS":
      return {
        ...state,
        signUp: {
          ...state.signUp,
          status: true,
        },
      };
    case "SIGNUP_FAILURE":
      return {
        ...state,
        signUp: {
          ...state.signUp,
          status: false,
        },
      };
    case "SIGNUP_ERROR":
      return {
        ...state,
        signUp: {
          ...state.signUp,
          error: payload,
        },
      };
    case "SIGNUP_LOADER":
      return {
        ...state,
        signUp: {
          ...state.signUp,
          loader: payload,
        },
      };

    case "SIGNUP-SIGNIN-VERIFY_RESET":
      return {
        ...state,
        signUp: {
          status: false,
          error: null,
          loader: false,
        },
        signIn: {
          status: false,
          error: null,
          loader: false,
        },
        verifyKeys: {
          status: false,
          error: null,
          loader: false,
        },
      };

    //   signIn types
    case "SIGNIN_SUCCESS":
      return {
        ...state,
        signIn: {
          ...state.signIn,
          status: true,
        },
      };

    case "SIGNIN_FAILURE":
      return {
        ...state,
        signIn: {
          ...state.signIn,
          status: false,
        },
        login: {
          status: false,
        },
      };

    case "SIGNIN_ERROR":
      return {
        ...state,
        signIn: {
          ...state.signIn,
          error: payload,
        },
      };

    case "SIGNIN_LOADER":
      return {
        ...state,
        signIn: {
          ...state.signIn,
          loader: payload,
        },
      };

    case "LOGIN_SUCCESS":
      return {
        ...state,
        login: {
          status: true,
        },
      };

    case "LOGOUT_FAILURE":
      return {
        ...state,
        login: {
          status: false,
        },
      };

    // verfify keys types
    case "VERIFY_KEYS_SUCCESS":
      return {
        ...state,
        verifyKeys: {
          ...state.verifyKeys,
          status: true,
        },
      };
    case "VERIFY_KEYS_FAILURE":
      return {
        ...state,
        verifyKeys: {
          ...state.verifyKeys,
          status: false,
        },
      };
    case "VERIFY_KEYS_ERROR":
      return {
        ...state,
        verifyKeys: {
          ...state.verifyKeys,
          error: payload,
        },
      };
    case "VERIFY_KEYS_LOADER":
      return {
        ...state,
        verifyKeys: {
          ...state.verifyKeys,
          loader: payload,
        },
      };

    case "SET_USER_DATA":
      return {
        ...state,
        user: payload,
      };

    case "SET_TOKENS":
      return {
        ...state,
        tokens: {
          access: payload["access"],
          refresh: payload["refresh"],
        },
      };

    case "RESET_TOKENS":
      return {
        ...state,
        tokens: {
          access: null,
          refresh: null,
        },
      };

    case "SET_USER_SUBSCRIPTION_STATUS":
      return {
        ...state,
        user: {
          ...state.user,
          active_subscription: true,
        },
      };

    case "LOGIN_POPUP_STATUS":
      return {
        ...state,
        loginPopUpStatus: payload,
      };

    case "SIGNUP_POPUP_STATUS":
      return {
        ...state,
        signUpPopUpStatus: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
