import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { url } from "../assets/constants";
import { Logout } from "../actions/auth.actions";

class Trade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pair: "",
      price: "",
      percentage: "",
      loader: false,
      error: null,
      message: "",
      market_pair: "",
      market_percentage: "",
      market_error: null,
      market_loader: false,
      market_message: "",
    };
  }

  _onTrade = (option) => {
    const { loader, error, percentage, pair, price } = this.state;

    if (pair != "" && price != "" && percentage != "") {
      this.setState({
        error: null,
        loader: true,
        message: null,
      });

      let order =
        option == "buy"
          ? "binance/binance-buy-order"
          : "binance/binance-sell-order";

      let end_point = url + order;

      let formData = new FormData();

      formData.append("pair", pair);
      formData.append("price", price);
      formData.append("percentage", percentage);

      axios
        .post(end_point, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((res) => {
          this.setState({
            error: null,
            loader: false,
            message: res.data.message,
            pair: "",
            price: "",
            percentage: "",
          });

          setTimeout(
            () =>
              this.setState({
                message: null,
                error: null,
              }),
            2000
          );
        })
        .catch((err) => {
          console.log(err);
          let error_status = err.response.status;
          if (error_status === 403) {
            this.props.Logout();
          }
          let error = err.response
            ? err.response.data && err.response.data.message
            : "Something went wrong. Please try again.";
          this.setState({
            error: error,
            loader: false,
            message: null,
            pair: "",
            price: "",
            percentage: "",
          });
        });
    } else {
      this.setState({
        error: "*all fields are mandatory.",
      });
    }
  };

  _onMarketTrade = (option) => {
    const {
      market_loader,
      market_error,
      market_pair,
      market_percentage,
      market_message,
    } = this.state;

    if (market_percentage != "" && market_pair != "") {
      this.setState({
        market_error: null,
        market_loader: true,
        market_message: null,
      });

      let order =
        option == "buy"
          ? "binance/binance-buy-market-order"
          : "binance/binance-sell-market-order";

      let end_point = url + order;

      let formData = new FormData();
      formData.append("pair", market_pair);
      formData.append("percentage", market_percentage);

      axios
        .post(end_point, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((res) => {
          this.setState({
            market_error: null,
            market_loader: false,
            market_message: res.data.message,
            market_pair: "",
            market_percentage: "",
          });

          setTimeout(
            () =>
              this.setState({
                market_message: null,
                market_error: null,
              }),
            2000
          );
        })
        .catch((err) => {
          console.log(err);
          let error_status = err.response.status;
          if (error_status === 403) {
            this.props.Logout();
          }
          let error = err.response
            ? err.response.data && err.response.data.message
            : "Something went wrong. Please try again.";
          this.setState({
            market_error: error,
            market_loader: false,
            market_message: null,
            market_pair: "",
            market_percentage: "",
          });
        });
    } else {
      this.setState({
        market_error: "*all fields are mandatory.",
      });
    }
  };
  render() {
    const {
      error,
      loader,
      pair,
      percentage,
      price,
      message,
      market_pair,
      market_percentage,
      market_error,
      market_loader,
      market_message,
    } = this.state;
    return (
      <div className="trade-page-container">
        <div className="row">
          <div className="col-lg-12">
            <div className="trade-page-content">
              <div>
                <div className="trade-input-container">
                  <label>Pair</label>
                  <input
                    name="pair"
                    type="text"
                    value={pair}
                    onChange={(evt) =>
                      this.setState({ [evt.target.name]: evt.target.value })
                    }
                  />
                </div>
                <div className="trade-input-container">
                  <label>Price</label>
                  <input
                    name="price"
                    type="text"
                    value={price}
                    onChange={(evt) =>
                      this.setState({ [evt.target.name]: evt.target.value })
                    }
                  />
                </div>
                <div className="trade-input-container">
                  <label>Percentage</label>
                  <input
                    name="percentage"
                    type="text"
                    value={percentage}
                    onChange={(evt) =>
                      this.setState({ [evt.target.name]: evt.target.value })
                    }
                  />
                </div>
              </div>
              <div>
                <p
                  className={!error ? "signup-success" : "signup-error"}
                  style={{ fontSize: "12px", marginLeft: "10px" }}
                >
                  {loader
                    ? "placing order..."
                    : error
                    ? error
                    : message
                    ? message
                    : null}
                </p>
              </div>
              <div>
                <button
                  className="buy-trade-button"
                  onClick={() => this._onTrade("buy")}
                >
                  LIMIT BUY
                </button>
                <button
                  className="sell-trade-button"
                  onClick={() => this._onTrade("sell")}
                >
                  LIMIT SELL
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="trade-page-content">
              <h3>MARKET TRADE OPTIONS</h3>
              <div>
                <div className="trade-input-container">
                  <label>Pair</label>
                  <input
                    name="market_pair"
                    type="text"
                    value={market_pair}
                    onChange={(evt) =>
                      this.setState({ [evt.target.name]: evt.target.value })
                    }
                  />
                </div>
                <div className="trade-input-container">
                  <label>Percentage</label>
                  <input
                    name="market_percentage"
                    type="text"
                    value={market_percentage}
                    onChange={(evt) =>
                      this.setState({ [evt.target.name]: evt.target.value })
                    }
                  />
                </div>
              </div>
              <div>
                <p
                  className={!market_error ? "signup-success" : "signup-error"}
                  style={{ fontSize: "12px", marginLeft: "10px" }}
                >
                  {market_loader
                    ? "placing order..."
                    : market_error
                    ? market_error
                    : market_message
                    ? market_message
                    : null}
                </p>
              </div>
              <div>
                <button
                  className="buy-trade-button"
                  onClick={() => this._onMarketTrade("buy")}
                >
                  MARKET BUY
                </button>
                <button
                  className="sell-trade-button"
                  onClick={() => this._onMarketTrade("sell")}
                >
                  MARKET SELL
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { Logout })(Trade);
