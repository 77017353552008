import axios from "axios";
import { url } from "../assets/constants";
import { Logout } from "./auth.actions";

export const getUserAccountDetails = () => (dispatch, getState) => {
  dispatch({
    type: "SET_ACCOUNT_DETAILS_LOADER",
    payload: true,
  });
  const { auth } = getState();
  const { tokens } = auth;

  let end_point = url + "binance/binance-account-details";

  axios
    .get(end_point, {
      headers: {
        Authorization: `Bearer ${tokens.access}`,
      },
    })
    .then((res) => {
      // console.log(res);
      let response = res.data.account.balances.filter(
        (dt) => parseFloat(dt.free) || parseFloat(dt.locked)
      );

      dispatch({
        type: "SET_ACCOUNT_DETAILS_DATA",
        payload: response,
        // payload: res.data.account.balances,
      });
      dispatch({
        type: "SET_ACCOUNT_DETAILS_LOADER",
        payload: false,
      });
    })
    .catch((err) => {
      let error = err.response.status;
      if (error === 403) {
        dispatch(Logout());
      }
      dispatch({
        type: "SET_ACCOUNT_DETAILS_ERROR",
        payload: "Something went wrong. Please try again.",
      });
      dispatch({
        type: "SET_ACCOUNT_DETAILS_LOADER",
        payload: false,
      });
    });
};

export const getUSerTradeDetails = () => (dispatch, getState) => {
  dispatch({
    type: "SET_TRADE_TABLE_LOADER",
    payload: true,
  });
  const { auth } = getState();
  const { tokens, user } = auth;

  let end_point = url + "binance/binance-trade";

  axios
    .get(end_point, {
      headers: {
        Authorization: `Bearer ${tokens.access}`,
      },
    })
    .then((res) => {
      // console.log(res);
      dispatch({
        type: "SET_TRADE_TABLE_DATA",
        payload: res.data,
      });
      dispatch({
        type: "SET_TRADE_TABLE_LOADER",
        payload: false,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: "SET_TRADE_TABLE_ERROR",
        payload: "Something went wrong. Please try again",
      });
      dispatch({
        type: "SET_TRADE_TABLE_LOADER",
        payload: false,
      });
    });
};

export const getbalancehistoryGraph = (isAdmin) => (dispatch, getState) => {
  dispatch({
    type: "SET_BALANCE_HISTORY_LOADER",
    payload: true,
  });

  const { auth } = getState();
  const { tokens, user } = auth;

  let end_point = isAdmin
    ? url + "binance/admin-binance-balance-history"
    : url + "binance/binance-balance-history";

  axios
    .get(end_point, {
      headers: {
        Authorization: `Bearer ${tokens.access}`,
      },
    })
    .then((res) => {
      // console.log(res);
      let response = res.data.map((item) => ({ ...item, name: item.date }));
      dispatch({
        type: "SET_BALANCE_HISTORY_DATA",
        payload: response,
      });
      dispatch({
        type: "SET_BALANCE_HISTORY_LOADER",
        payload: false,
      });
    })
    .catch((err) => {
      let error = err.response.status;
      if (error === 403) {
        dispatch(Logout());
      }
      dispatch({
        type: "SET_BALANCE_HISTORY_ERROR",
        payload: "Something went wrong. Please try again.",
      });
      dispatch({
        type: "SET_BALANCE_HISTORY_LOADER",
        payload: false,
      });
    });
};

export const getCoinsHistory = () => (dispatch, getState) => {
  dispatch({
    type: "SET_COIN_DETAILS_LOADER",
    payload: true,
  });

  const { auth } = getState();
  const { tokens, user } = auth;

  let end_point = url + "binance/admin-binance-coins-details";

  axios
    .get(end_point, {
      headers: {
        Authorization: `Bearer ${tokens.access}`,
      },
    })
    .then((res) => {
      // console.log(res.data.admin_balance);
      // console.log(Object.entries(res.data.admin_balance[0]));
      // debugger;
      let response = Object.entries(res.data.admin_balance[0]).map((item) => {
        return {
          asset: item[0],
          free: parseFloat(item[1]["free"]).toFixed(8),
          locked: parseFloat(item[1]["locked"]).toFixed(8),
        };
      });
      // console.log(response);
      dispatch({
        type: "SET_COIN_DETAILS_DATA",
        payload: response,
      });
      dispatch({
        type: "SET_COIN_DETAILS_LOADER",
        payload: false,
      });
    })
    .catch((err) => {
      let error = err.response.status;
      if (error === 403) {
        dispatch(Logout());
      }
      dispatch({
        type: "SET_COIN_DETAILS_ERROR",
        payload: "Something went wrong. Please try again.",
      });
      dispatch({
        type: "SET_COIN_DETAILS_LOADER",
        payload: false,
      });
    });
};

// export const changeUserPassword =
//   (old_password, new_password, confirm_password) => (dispatch, getState) => {
//     let end_point = url + "/api/v1/auth/update-password";

//     let formData = new FormData();
//     formData.append("old_password", old_password);
//     formData.append("new_password", new_password);
//     formData.append("confirm_password", confirm_password);

//     axios
//       .patch(end_point, formData, {
//         headers: {
//           Authorization: `Bearer ${tokens.access}`,
//         },
//       })
//       .then((res) => console.log(res))
//       .catch((res) => console.log(res));
//   };
