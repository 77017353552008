import React, { useState, useEffect } from "react";

import axios from "axios";
import { url } from "../assets/constants";

import { setUserSubscriptionStatus } from "../actions/auth.actions";
import { connect } from "react-redux";

function Payments({ setUserSubscriptionStatus }) {
  var interval = "";
  const [previousPayments, setpreviousPayments] = useState([]);
  const [paymentStatus, setpaymentStatus] = useState(null);
  const [paymentError, setpaymentError] = useState("");
  const [selectValue, setselectValue] = useState(null);
  const [coinError, setcoinError] = useState(false);
  useEffect(() => {
    getPreviousPaymentDetails();
    interval = setInterval(() => {
      getPaymentStatus();
    }, 5000);
  }, []);

  const getPaymentStatus = () => {
    let end_point = url + "api/v1/auth/account-subscription-status";

    axios
      .get(end_point, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((res) => {
        if (res.data.active_subscription) {
          let user = JSON.parse(localStorage.getItem("user"));
          user = { ...user, active_subscription: true };
          localStorage.setItem("user", JSON.stringify(user));
          setUserSubscriptionStatus();
          getPreviousPaymentDetails();
          clearInterval(interval);
        }
      });
  };

  const getPreviousPaymentDetails = () => {
    let end_point = url + "api/v1/auth/payment-history";

    axios
      .get(end_point, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((res) => {
        setpreviousPayments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const makePayment = () => {
    setpaymentError("");
    let end_point = url + "api/v1/auth/payment";

    if (!selectValue) {
      setcoinError(true);
      return;
    }

    let formData = {
      amount: 100,
      payable_currency: selectValue,
    };

    axios
      .post(end_point, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((res) => {
        if (res.data.checkout_url) {
          window.location.href = res.data.checkout_url;
        } else {
          setpaymentError(res.data.error);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    if (e.target.value) {
      setcoinError(false);
      setselectValue(e.target.value);
    }
  };
  return (
    <div className="container">
      <div className="row pt-5">
        <div className="admin-dashboard-trade-details-section">
          <div className="col-lg-12">
            <div className="section-header">
              <div className="page-heading">
                <h2>Payments</h2>
                <p>click pay now button to make a payment</p>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div
              style={{
                minHeight: "350px",
                width: "230px",
                margin: "15px",
                border: "1px solid #ccc",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "15px",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <h5>One-Month Subscription</h5>
                <p style={{ fontSize: "16px" }}>
                  By clicking Pay Now you agree to subscribe for 1 Month
                </p>
                <p style={{ fontSize: "14px" }}>
                  Note: Your subscription isn’t recurring (You need to re-new by
                  the end of your monthly cycle)
                </p>
                <h2>
                  <sup>$</sup>100
                </h2>
              </div>
              <select value={selectValue} onChange={handleChange}>
                <option value="">Select a coin to pay</option>
                <option value="BTC">Bitcoin</option>
                <option value="BCH">Bitcoin Cash</option>
                <option value="LTC">Litecoin</option>
                <option value="ADA.BEP2">Cardano (BC Chain)</option>
                <option value="ETC">Ether Classic</option>
                <option value="ETH">Ether</option>
                <option value="USDT.BEP2">Tether USD (BC Chain)</option>
                <option value="USDT.BEP20">Tether USD (BSC Chain)</option>
                <option value="USDT.ERC20">Tether USD (ERC20)</option>
                <option value="XRP.BEP2">XRP Token (BC Chain)</option>
              </select>
              {coinError && (
                <p style={{ color: "red" }}>Please select a coin</p>
              )}
              <button
                className="email-page-button"
                onClick={() => makePayment()}
              >
                Pay Now
              </button>
            </div>
            <div style={{ width: "calc(100% - 200px)", minHeight: "350px" }}>
              <h6 style={{ fontWeight: "700", fontSize: "14px" }}>
                Previous Payment Details
              </h6>
              {previousPayments.length ? (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Subscription Start Date</th>
                        <th>Subscription End Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {previousPayments.map((payment, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {new Date(
                              payment.subscription_start_date
                            ).toDateString()}
                          </td>
                          <td>
                            {new Date(
                              payment.subscription_end_date
                            ).toDateString()}
                          </td>
                          <td>{payment.is_active ? "Active" : "In-Active"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>No Payments Found</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { setUserSubscriptionStatus })(
  Payments
);
