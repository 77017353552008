import axios from "axios";

import { url } from "../assets/constants";

export const userSignUp =
  (first_name, last_name, email, password, country, dob, phone) =>
  (dispatch) => {
    dispatch({
      type: "SIGNUP_LOADER",
      payload: true,
    });
    let formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("country", country);
    formData.append("date_of_birth", dob);
    formData.append("phone", phone);
    let end_point = url + "api/v1/auth/signup";

    axios
      .post(end_point, formData)
      .then((res) => {
        dispatch({
          type: "SIGNUP_SUCCESS",
        });
        dispatch({
          type: "SIGNUP_LOADER",
          payload: false,
        });
      })
      .catch((err) => {
        console.log(err, err.response);
        let error = err.response
          ? err.response.data && err.response.data.error
          : "Something went wrong. Please try again.";
        dispatch({
          type: "SIGNUP_FAILURE",
        });
        dispatch({
          type: "SIGNUP_ERROR",
          payload: error,
        });
        dispatch({
          type: "SIGNUP_LOADER",
          payload: false,
        });
      });
  };

export const resetSignUpSignInStatus = () => (dispatch) => {
  dispatch({
    type: "SIGNUP-SIGNIN_RESET",
  });
};

export const userSignIn = (email, password) => (dispatch) => {
  dispatch({
    type: "SIGNIN_LOADER",
    payload: true,
  });
  let formData = new FormData();
  formData.append("email", email);
  formData.append("password", password);
  let end_point = url + "api/v1/auth/login";
  axios
    .post(end_point, formData)
    .then((res) => {
      if (res.data.api_keys_exist) {
        localStorage.setItem("login_status", true);
        dispatch({ type: "LOGIN_SUCCESS" });
      }
      if (res.data.profile.is_verified) {
        dispatch({
          type: "SIGNIN_SUCCESS",
        });
        dispatch({
          type: "SIGNIN_LOADER",
          payload: false,
        });
        dispatch({
          type: "SET_USER_DATA",
          payload: res.data.profile,
        });
        dispatch({
          type: "SET_TOKENS",
          payload: res.data,
        });
        localStorage.setItem("access", res.data.access);
        localStorage.setItem("refresh", res.data.refresh);
        localStorage.setItem("user", JSON.stringify(res.data.profile));
      } else {
        dispatch({
          type: "SIGNIN_FAILURE",
        });
        dispatch({
          type: "SIGNIN_ERROR",
          payload:
            "Please verify your account and then sign in to your account.",
        });
        dispatch({
          type: "SIGNIN_LOADER",
          payload: false,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      localStorage.setItem("login_status", false);
      let error = err.response
        ? err.response.data && err.response.data.error
        : "Something went wrong. Please try again.";
      dispatch({
        type: "SIGNIN_FAILURE",
      });
      dispatch({
        type: "SIGNIN_ERROR",
        payload: error,
      });
      dispatch({
        type: "SIGNIN_LOADER",
        payload: false,
      });
    });
};

export const userVerifyKeys = (api_key, api_secret) => (dispatch, getState) => {
  dispatch({
    type: "VERIFY_KEYS_LOADER",
    payload: true,
  });

  const { auth } = getState();
  const { user, tokens } = auth;
  // console.log(user);

  let formData = new FormData();
  formData.append("api_key", api_key);
  formData.append("api_secret", api_secret);
  formData.append("user", user["id"]);

  let end_point = url + "binance/binance-keys";
  // console.log(tokens);
  axios
    .post(end_point, formData, {
      headers: { Authorization: `Bearer ${tokens.access}` },
    })
    .then((res) => {
      localStorage.setItem("login_status", true);
      dispatch({
        type: "LOGIN_SUCCESS",
      });
      dispatch({
        type: "VERIFY_KEYS_SUCCESS",
      });
      dispatch({
        type: "VERIFY_KEYS_LOADER",
        payload: true,
      });
    })
    .catch((err) => {
      // console.log(err);
      localStorage.setItem("login_status", false);
      let error = err.response
        ? err.response.data && err.response.data.error
        : "Something went wrong. Please try again.";
      // console.log(error);
      dispatch({
        type: "VERIFY_KEYS_FAILURE",
      });
      dispatch({
        type: "VERIFY_KEYS_ERROR",
        payload: error,
      });
      dispatch({
        type: "VERIFY_KEYS_LOADER",
        payload: true,
      });
    });
};

export const userDisconnect = () => (dispatch, getState) => {
  const { auth } = getState();
  const { user, tokens } = auth;

  let end_point = url + "binance/binance-keys";

  axios
    .delete(end_point, {
      headers: { Authorization: `Bearer ${tokens.access}` },
    })
    .then((res) => {
      console.log(res);
      alert("Disconnected Successfully");
      dispatch(Logout());
    })
    .catch((err) => {
      console.log(err);
      let error = err.response
        ? err.response.data && err.response.data.message
        : "Something went wrong. Please try again.";
      alert(error);
    });
};

export const Logout = () => (dispatch) => {
  dispatch({
    type: "LOGOUT_FAILURE",
  });
  dispatch({
    type: "SIGNIN_FAILURE",
  });
  dispatch({
    type: "RESET_TOKENS",
  });
  localStorage.clear();
};

export const setUserSubscriptionStatus = () => (dispatch) => {
  dispatch({
    type: "SET_USER_SUBSCRIPTION_STATUS",
    payload: true,
  });
};

export const toggleLoginPopUpStatus = (status) => (dispatch) => {
  dispatch({
    type: "LOGIN_POPUP_STATUS",
    payload: status,
  });
};

export const toggleSignUpPopUpStatus = (status) => (dispatch) => {
  dispatch({
    type: "SIGNUP_POPUP_STATUS",
    payload: status,
  });
};
