const initialState = {
  dashboard: {
    page_loader: true,
    tradeTable: {
      loader: false,
    },
    accountDetails: {
      loader: false,
    },
    balanceHistory: {
      loader: false,
    },
    coinsDetails: {
      loader: false,
    },
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_DASHBOARD_LOADER":
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          page_loader: payload,
        },
      };

    case "SET_TRADE_TABLE_LOADER":
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          tradeTable: {
            ...state.dashboard.tradeTable,
            loader: payload,
          },
        },
      };

    case "SET_TRADE_TABLE_DATA":
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          tradeTable: {
            ...state.dashboard.tradeTable,
            data: payload,
            error: null,
          },
        },
      };

    case "SET_TRADE_TABLE_ERROR":
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          tradeTable: {
            ...state.dashboard.tradeTable,
            data: [],
            error: payload,
          },
        },
      };

    case "SET_ACCOUNT_DETAILS_LOADER":
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          accountDetails: {
            ...state.dashboard.accountDetails,
            loader: payload,
          },
        },
      };

    case "SET_ACCOUNT_DETAILS_DATA":
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          accountDetails: {
            ...state.dashboard.accountDetails,
            data: payload,
            error: null,
          },
        },
      };

    case "SET_ACCOUNT_DETAILS_ERROR":
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          accountDetails: {
            ...state.dashboard.accountDetails,
            error: payload,
            data: [],
          },
        },
      };

    case "SET_BALANCE_HISTORY_LOADER":
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          balanceHistory: {
            ...state.dashboard.balanceHistory,
            loader: payload,
          },
        },
      };

    case "SET_BALANCE_HISTORY_DATA":
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          balanceHistory: {
            ...state.dashboard.balanceHistory,
            data: payload,
            error: null,
          },
        },
      };

    case "SET_BALANCE_HISTORY_ERROR":
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          balanceHistory: {
            ...state.dashboard.balanceHistory,
            data: [],
            error: payload,
          },
        },
      };

    case "SET_COIN_DETAILS_LOADER":
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          coinsDetails: {
            ...state.dashboard.coinsDetails,
            loader: payload,
          },
        },
      };

    case "SET_COIN_DETAILS_DATA":
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          coinsDetails: {
            ...state.dashboard.coinsDetails,
            data: payload,
            error: null,
          },
        },
      };

    case "SET_COIN_DETAILS_ERROR":
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          coinsDetails: {
            ...state.dashboard.coinsDetails,
            data: [],
            error: payload,
          },
        },
      };

    default:
      return {
        ...state,
      };
  }
};
