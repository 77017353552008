import axios from "axios";
import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import { Logout } from "../actions/auth.actions";

import {
  getUserAccountDetails,
  getUSerTradeDetails,
  getbalancehistoryGraph,
  getCoinsHistory,
} from "../actions/application.action";
import { userDisconnect } from "../actions/auth.actions";
import { url } from "../assets/constants";

import LineChart from "../components/LineChart";
import { NavLink, Route } from "react-router-dom";
import SideBar from "../components/SideBar";
import Trade from "./Trade";
// import "../assets/css/dashboard.css";

class Dashboard extends Component {
  componentDidMount() {
    const { user } = this.props;
    const { user_role } = user;

    this.props.getUserAccountDetails();
    this.props.getUSerTradeDetails();
    if (user_role === "Admin") {
      // this.props.getUSerTradeDetails();
      this.props.getbalancehistoryGraph(true);
      this.props.getCoinsHistory();
    } else {
      // this.props.getUserAccountDetails();
      // this.props.getUSerTradeDetails();
      this.props.getbalancehistoryGraph(false);
    }
  }

  _renderAccountDetails = (dataItems) => {
    const { data, loader, error } = dataItems;
    // console.log(data, loader, error);
    const ExpandedComponent = ({ data }) => (
      <pre>{JSON.stringify(data, null, 2)}</pre>
    );

    const columns = [
      {
        name: "Asset",
        selector: (row) => row.asset,
      },
      {
        name: "Free",
        selector: (row) => row.free,
      },
      {
        name: "Locked",
        selector: (row) => row.locked,
      },
    ];

    return loader ? (
      <div className="loader-state">loading account details...</div>
    ) : error ? (
      <div>{error}</div>
    ) : data ? (
      <DataTable columns={columns} data={data} pagination />
    ) : (
      <div>No Data to Display.</div>
    );
  };

  _renderCoinsDetails = (dataItems) => {
    const { data, loader, error } = dataItems;

    const columns = [
      {
        name: "Asset",
        selector: (row) => row.asset,
      },
      {
        name: "Free",
        selector: (row) => row.free,
      },
      {
        name: "Locked",
        selector: (row) => row.locked,
      },
    ];

    return loader ? (
      <div className="loader-state">loading account details...</div>
    ) : error ? (
      <div>{error}</div>
    ) : data ? (
      <DataTable columns={columns} data={data} pagination />
    ) : (
      <div>No Data to Display.</div>
    );
  };

  _renderTradeDetails = (dataItems) => {
    const { data, loader, error } = dataItems;
    const { user } = this.props;
    const { user_role } = user;
    // console.log(data, loader, error);
    const ExpandedComponent = ({ data }) => (
      <pre>{JSON.stringify(data, null, 2)}</pre>
    );

    const columns = [
      {
        name: "Symbol",
        selector: (row) => row.symbol,
      },
      {
        name: "Order Id",
        selector: (row) => row.orderId,
      },
      {
        name: "Price",
        selector: (row) => parseFloat(row.price).toFixed(2),
        sortable: true,
      },
      {
        name: "Original Quantity",
        selector: (row) => parseFloat(row.origQty).toFixed(2),
      },
      {
        name: "Executed Quanity",
        selector: (row) => parseFloat(row.executedQty).toFixed(2),
      },
      {
        name: "Order Type",
        selector: (row) => row.type,
      },
      {
        name: "Order Side",
        selector: (row) => row.side,
      },
      {
        name: "Created",
        selector: (row) => moment(row.created).format("YYYY-MM-DD"),
        sortable: true,
      },
      {
        name: "Modified",
        selector: (row) => moment(row.modified).format("YYYY-MM-DD"),
        sortable: true,
      },
      {
        name: "Status",
        selector: (row) => row.status,
      },

      user_role === "Admin" && {
        cell: (row) => (
          <button
            className={
              row.status === "FILLED" || row.status === "CANCELED"
                ? "cancel-order-disabled-button"
                : "cancel-order-button"
            }
            onClick={() => this._onCancelOrder(row.status, row.orderId)}
          >
            Cancel
          </button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];

    return loader ? (
      <div className="loader-state">loading trade details...</div>
    ) : error ? (
      <div>{error}</div>
    ) : data ? (
      <DataTable columns={columns} data={data} pagination />
    ) : (
      <div>No Data to Display.</div>
    );
  };

  _onCancelOrder = (status, orderId) => {
    if (status !== "FILLED" && status !== "CANCELED") {
      let end_point = url + "binance/binance-cancel-order";

      let formdata = new FormData();
      formdata.append("order_id", orderId);

      axios
        .post(end_point, formdata, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((res) => {
          // console.log(res);
          alert(res.data.message);
          this.props.getUSerTradeDetails();
        })
        .catch((err) => {
          console.log(err);
          let error_status = err.response.status;
          if (error_status === 403) {
            this.props.Logout();
          }
          alert(
            "Something went wrong while canceling your order. Please contact support!"
          );
        });
    }
  };

  _renderLineChart = (dataItems) => {
    // console.log(dataItems);
    const { data, loader, error } = dataItems;

    return loader ? (
      <div className="loader-state">loading balance history...</div>
    ) : error ? (
      <div>{error}</div>
    ) : data && data.length ? (
      <LineChart
        data={data}
        lines={["BNB", "BTC", "BUSD", "ETH", "LTC", "TRX", "USDT", "XRP"]}
        showTooltip={true}
      />
    ) : (
      <div className="loader-state">No Balance History to display</div>
    );
  };

  _onReload = () => {
    // console.log("on reload");
    let end_point = url + "binance/binance-update-trade-status";

    axios
      .post(end_point, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((res) => {
        // console.log(res);
        alert(
          "Orders update is in progress, hit Refresh button after some time to see updated data."
        );
      })
      .catch((err) => {
        console.log(err);
        let error_status = err.response.status;
        if (error_status === 403) {
          this.props.Logout();
        }
      });
  };

  _onDisconnect = () => {
    this.props.userDisconnect();
  };

  _downloadCSV = (args, csvData) => {
    const convertArrayOfObjectsToCSV = (args) => {
      var result, ctr, keys, columnDelimiter, lineDelimiter, data;

      data = args.data || null;
      if (data == null || !data.length) {
        return null;
      }

      columnDelimiter = args.columnDelimiter || ",";
      lineDelimiter = args.lineDelimiter || "\n";

      keys = Object.keys(data[0]);
      console.log(keys);

      result = "";
      result += keys.join(columnDelimiter);
      result += lineDelimiter;

      data.forEach(function (item) {
        ctr = 0;
        keys.forEach(function (key) {
          if (ctr > 0) result += columnDelimiter;
          result += item[key];
          ctr++;
        });
        result += lineDelimiter;
      });

      return result;
    };
    var data, filename, link;
    var csv = convertArrayOfObjectsToCSV({
      data: csvData,
    });
    if (csv == null) return;

    filename = args.filename || "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/csv;charset=utf-8," + csv;
    }
    data = encodeURI(csv);

    link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
  };

  render() {
    const { dashboard, user } = this.props;
    const { user_role } = user;
    const { accountDetails, tradeTable, balanceHistory, coinsDetails } =
      dashboard;
    return (
      <div className="container" style={{ background: "rgb(240, 242, 245)" }}>
        <div className="admin-dashboard-page-content">
          <div className="row">
            {/* {user_role !== "Admin" && ( */}
            <div className="col-lg-12 py-5">
              <div
                className={
                  user_role !== "Admin"
                    ? "user-dashboard-trade-details-section"
                    : "admin-dashboard-trade-details-section"
                }
              >
                <div className="section-header">
                  <div className="page-heading">
                    <h2>Performance Chart</h2>
                  </div>
                  {user_role !== "Admin" && (
                    <div className="right-section">
                      <button
                        className="disconnect-button"
                        onClick={() => this._onDisconnect()}
                      >
                        Disconnect
                      </button>
                    </div>
                  )}
                </div>
                {this._renderLineChart(balanceHistory)}
              </div>
            </div>
            {/* )} */}
            {/* {user_role !== "Admin" && ( */}
            <div className="col-lg-12 py-5">
              <div
                className={
                  user_role !== "Admin"
                    ? "user-dashboard-trade-details-section"
                    : "admin-dashboard-trade-details-section"
                }
              >
                <div className="section-header">
                  <div className="page-heading">
                    {user_role === "Admin" ? (
                      <h2>Trader Account</h2>
                    ) : (
                      <h2>Account Details</h2>
                    )}
                    {/* <p>Table</p> */}
                  </div>
                </div>
                {this._renderAccountDetails(accountDetails)}
              </div>
            </div>
            {/* )} */}
            {user_role === "Admin" && (
              <div className="col-lg-12 py-5">
                <div className="admin-dashboard-trade-details-section">
                  <div className="section-header">
                    <div className="page-heading">
                      <h2>Total Followers Equities</h2>
                      {/* <p>Table</p> */}
                    </div>
                  </div>
                  {this._renderCoinsDetails(coinsDetails)}
                </div>
              </div>
            )}
            {/*Trade Section*/}
            {user_role === "Admin" && (
              <div className="col-lg-12 py-5">
                <div
                  className={
                    user_role !== "Admin"
                      ? "user-dashboard-trade-details-section"
                      : "admin-dashboard-trade-details-section"
                  }
                >
                  <div className="section-header">
                    <div className="page-heading">
                      <h2>Trade Section</h2>
                      <p>LIMIT TRADE OPTIONS</p>
                    </div>
                  </div>
                  {user_role === "Admin" && (
                    <React.Fragment>
                      <Trade />
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}
            <div className="col-lg-12 py-5">
              <div
                className={
                  user_role !== "Admin"
                    ? "user-dashboard-trade-details-section"
                    : "admin-dashboard-trade-details-section"
                }
              >
                <div className="section-header">
                  <div className="page-heading">
                    <h2>Trade Details</h2>
                    <p>Table for all the live transactions on the account</p>
                  </div>
                  <div className="right-section">
                    <button
                      className="reload-button"
                      onClick={() => this._onReload()}
                    >
                      Reload
                    </button>
                    <button
                      className="refresh-button"
                      onClick={() => this.props.getUSerTradeDetails()}
                    >
                      Refresh
                    </button>
                    <button
                      className="download-csv-button"
                      onClick={() =>
                        tradeTable && tradeTable.data
                          ? this._downloadCSV(
                              { filename: "trade_details.csv" },
                              tradeTable.data
                            )
                          : alert("No data present to download.")
                      }
                    >
                      Export CSV
                    </button>
                  </div>
                </div>
                {this._renderTradeDetails(tradeTable)}
              </div>
            </div>
            {/* {user_role === "Admin" && (
              <div className="col-lg-12 py-5">
                <div
                  className={
                    user_role !== "Admin"
                      ? "user-dashboard-trade-details-section"
                      : "admin-dashboard-trade-details-section"
                  }
                >
                  <div className="section-header">
                    <div className="page-heading">
                      <h2>Chart Section</h2>
                      <p></p>
                    </div>
                  </div>
                  <div className="text-center">
                    {this._renderLineChart(balanceHistory)}
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.application.dashboard,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getUserAccountDetails,
  getUSerTradeDetails,
  getbalancehistoryGraph,
  userDisconnect,
  getCoinsHistory,
  Logout,
})(Dashboard);
