import React from "react";
import { Link } from "react-router-dom";

import Logo from "../../assets/image/logo_new.png";
import Coin4 from "../../assets/image/coins/4.png";
import Coin5 from "../../assets/image/coins/5.png";
import Coin6 from "../../assets/image/coins/6.png";

export default function Navbar(props) {
  const { setloginPopUp, _toggleNavigation, setsignUpPopUp, navbarOpen } =
    props;

  const capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1);
  };
  return (
    <div
      class={
        window.location.pathname === "/" ||
        window.location.pathname === "/activate"
          ? "main"
          : "main-inside"
      }
    >
      <div
        class={
          window.location.pathname === "/" ||
          window.location.pathname === "/activate"
            ? "layout-ground"
            : "layout-inside"
        }
      >
        {/* <!-- start navbar --> */}
        <nav class="navbar navbar-expand-md navbar-dark fixed-top">
          <div class="container-fluid">
            <div class="collapse navbar-collapse order-1 w-100" id="navbarNav1">
              <div class="me-auto ">
                <Link to="/" className="navbar-brand">
                  <img src={Logo} alt="" class="img-fluid d-none d-sm-block" />
                </Link>
              </div>
              <ul class="navbar-nav mx-auto">
                <li class="nav-item">
                  <Link
                    to="/"
                    className="nav-link"
                    activeClassName="nav-link active"
                    aria-current="page"
                  >
                    <span>Home</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    to="/about"
                    className="nav-link"
                    activeClassName="nav-link active"
                    aria-current="page"
                  >
                    <span>About Us</span>
                  </Link>
                </li>
                <li className="nav-item">
                  {/* <Link
                      to="/"
                      className="nav-link"
                      activeClassName="nav-link active"
                      aria-current="page"
                  > */}
                  <a
                    href="https://blog.koinstrade.com/"
                    className="nav-link"
                    target="_blank"
                  >
                    <span>Blog</span>
                  </a>
                  {/* </Link> */}
                </li>
                <li class="nav-item">
                  <Link
                    to="/faq"
                    className="nav-link"
                    activeClassName="nav-link active"
                    aria-current="page"
                  >
                    <span>FAQ</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/contact"
                    className="nav-link"
                    activeClassName="nav-link active"
                    aria-current="page"
                  >
                    <span>Contact Us</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    to="/pricing"
                    className="nav-link"
                    activeClassName="nav-link active"
                    aria-current="page"
                  >
                    <span>Pricing</span>
                  </Link>
                </li>
              </ul>
              <div class="ms-auto" style={{ marginRight: "25px" }}>
                <button
                  class="btn btn-outline-light m-1"
                  data-bs-toggle="modal"
                  data-bs-target="#loginModal"
                  onClick={() => setloginPopUp(true)}
                >
                  Login
                </button>
                <button
                  class="btn btn-outline-light m-1"
                  data-bs-toggle="modal"
                  data-bs-target="#registerModal"
                  onClick={() => setsignUpPopUp(true)}
                >
                  Register
                </button>
              </div>
            </div>
            <div class="mx-auto order-0">
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => _toggleNavigation(!navbarOpen)}
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <Link to="/" className="navbar-brand">
                <img
                  src={Logo}
                  style={{ width: "14rem" }}
                  alt=""
                  class="img-fluid d-lg-none"
                />
              </Link>
            </div>
          </div>
        </nav>

        <section class="about-video index-video">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 video-box">
                <a
                  href="https://blog.koinstrade.com/tutorials/"
                  class="glightbox play-btn mb-4"
                  data-vbtype="video"
                  data-autoplay="true"
                  target="_blank"
                ></a>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- end navbar --> */}
        {window.location.pathname === "/" ||
        window.location.pathname === "/activate" ? (
          <div class="coin-section">
            {/* <div class="slider">
              <div class="circular-slider circular-slider-1">
                <div class="wrapper">
                  <div class="controls ">
                    <div class="controls__left" style={{ display: "none" }}>
                      <div class="icon-wrapper">
                        <i class="far fa-arrow-alt-circle-left"></i>
                      </div>
                    </div>
                    <div class="controls__right" style={{ display: "none" }}>
                      <div class="icon-wrapper">
                        <i class="far fa-arrow-alt-circle-right"></i>
                      </div>
                    </div>
                    <div class="controls__autoplay controls__autoplay_running d-none">
                      <div class="icon-wrapper">
                        <div class="pause">
                          <i class="far fa-pause-circle"></i>
                        </div>
                        <div class="run">
                          <i class="far fa-play-circle"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="slides-holder">
                    <div class="slides-holder__item slides-holder__item_active">
                      <img src={Coin4} alt="img" />
                    </div>
                    <div class="slides-holder__item">
                      <img src={Coin5} alt="img" />
                    </div>
                    <div class="slides-holder__item">
                      <img src={Coin6} alt="img" />
                    </div>
                  </div>
                  <div class="descriptions d-none">
                    <div class="descriptions__item descriptions__item_visible">
                      <h1></h1>
                      <p class="description"></p>
                    </div>
                    <div class="descriptions__item">
                      <h1></h1>
                      <p class="description"></p>
                    </div>
                    <div class="descriptions__item">
                      <h1></h1>
                      <p class="description"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="coin-image-slider-base">
              <div className="coin-image-slider"></div>
              <div className="coin-thread">
                <img className="coin-image" src={Coin4} alt="img" />
              </div>
              <div className="coin-thread1">
                <img className="coin-image1" src={Coin5} alt="img" />
              </div>
              <div className="coin-thread2">
                <img className="coin-image2" src={Coin6} alt="img" />
              </div>
            </div>
          </div>
        ) : (
          <section id="breadcrumbs" class="breadcrumb">
            <div class="container">
              <div class="d-flex justify-content-between align-items-center">
                <ol>
                  {[
                    "contact",
                    "about",
                    "blog",
                    "faq",
                    "pricing",
                    "payment",
                    "privacy",
                    "support",
                    "terms",
                    // "verify",
                  ].includes(window.location.pathname.replace("/", "")) ? (
                    <>
                      <li>
                        <Link to="/" style={{ textDecoration: "none" }}>
                          Home
                        </Link>
                      </li>
                      <li>
                        {capitalize(window.location.pathname.replace("/", ""))}
                      </li>
                    </>
                  ) : (
                    <li>Page Not Found!</li>
                  )}
                </ol>
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
}
