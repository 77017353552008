import React, { useEffect } from "react";

import { connect } from "react-redux";

import { toggleSignUpPopUpStatus } from "../../actions/auth.actions";

function Pricing(props) {
  const { toggleSignUpPopUpStatus } = props;
  useEffect(() => {
    scrollToTop();
    return;
  }, []);

  const scrollToTop = () => {
    document
      .getElementsByClassName("parent-main")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <section id="pricing" class="section-pricing">
        <div class="container">
          <div class="row">
            <div class="main-title">
              <h2>
                <span>Pricing</span>
              </h2>
              <p>KoinsTrade is the first auto trade system for Binance users.
                  Purchase and give a trading access to our experts to trade crypto on your behalf.
                  With only 100 USD, you will buy many years of experience in trading with almost Zero risk!
                  Watch the <a href="https://blog.koinstrade.com/tutorials"  target="_blank" style={{fontWeight: "bold", color: "#a14caf", textDecoration: "none"}}>Tutorial Video</a> to know more about the subscription process.</p>
              {/*<p>*/}
              {/*  <span>Title Title Title</span>*/}
              {/*</p>*/}
            </div>
          </div>

          <div class="row" style={{ justifyContent: "center" }}>
            {/*<div class="col-lg-4 col-md-6">*/}
            {/*  <div class="box">*/}
            {/*    <h3>Normal</h3>*/}
            {/*    <h4>*/}
            {/*      <sup>$</sup>19<span> / month</span>*/}
            {/*    </h4>*/}
            {/*    <ul>*/}
            {/*      <li>AAAAAA AAAAAAA</li>*/}
            {/*      <li>AAAAAA AAAAAAA AAAA</li>*/}
            {/*      <li>AAAAAA AAAAAAA AAAAAAAA</li>*/}
            {/*      <li class="disabled">AAAAAA AAAAAAA</li>*/}
            {/*      <li class="disabled">AAAAAA AAAAAAA AAAA</li>*/}
            {/*    </ul>*/}
            {/*    <div class="btn-wrap">*/}
            {/*      <a href="#" class="btn-subscribe">*/}
            {/*        Subscribe Now*/}
            {/*      </a>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div style={{color: "white", fontSize: "14px"}}>

            </div>
            <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
              <div class="box featured">
                {/*<h3>Gold</h3>*/}
                <h4>
                  <sup>$</sup>100<span> / month</span>
                </h4>
                {/*<ul>*/}
                {/*  <li>AAAAAA AAAAAAA</li>*/}
                {/*  <li>AAAAAA AAAAAAA AAAA</li>*/}
                {/*  <li>AAAAAA AAAAAAA AAAAAAAA</li>*/}
                {/*  <li>AAAAAA AAAAAAA</li>*/}
                {/*  <li class="disabled">AAAAAA AAAAAAA AAAA</li>*/}
                {/*</ul>*/}
                <div class="btn-wrap">
                  <a
                    class="btn-subscribe"
                    onClick={() => toggleSignUpPopUpStatus(true)}
                  >
                    Sign Up Now
                  </a>
                </div>
              </div>
            </div>

            {/*<div class="col-lg-4 col-md-6 mt-4 mt-lg-0">*/}
            {/*  <div class="box">*/}
            {/*    <h3>Platinum</h3>*/}
            {/*    <h4>*/}
            {/*      <sup>$</sup>50<span> / month</span>*/}
            {/*    </h4>*/}
            {/*    <ul>*/}
            {/*      <li>AAAAAA AAAAAAA</li>*/}
            {/*      <li>AAAAAA AAAAAAA AAAA</li>*/}
            {/*      <li>AAAAAA AAAAAAA AAAAAAAA</li>*/}
            {/*      <li>AAAAAA AAAAAAA</li>*/}
            {/*      <li>AAAAAA AAAAAAA AAAA</li>*/}
            {/*    </ul>*/}
            {/*    <div class="btn-wrap">*/}
            {/*      <a href="#" class="btn-subscribe">*/}
            {/*        Subscribe Now*/}
            {/*      </a>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </section>
      {/*<section class="section-cta">*/}
      {/*  <div class="container">*/}
      {/*    <div class="text-center">*/}
      {/*      <h3>Ask for free trial</h3>*/}
      {/*      /!* <p>we are just joke hhhhhhhhhhhhhhhhhhhhhh</p> *!/*/}
      {/*      <a class="cta-btn" href="#">*/}
      {/*        request a call back*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}

      {/*<div class="section-posts">*/}
      {/*  <div class="container">*/}
      {/*    <div class="row">*/}
      {/*      <div class="main-title">*/}
      {/*        <h2>*/}
      {/*          <span>[Pricing] Frequently Asked Question</span>*/}
      {/*        </h2>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div class="row">*/}
      {/*      <div class="col-lg-12 col-md-12 col-sm-12">*/}
      {/*        <div class="accordion accordion-flush" id="ac1">*/}
      {/*          <div class="accordion-item">*/}
      {/*            <h2 class="accordion-header" id="head1">*/}
      {/*              <button*/}
      {/*                class="accordion-button collapsed"*/}
      {/*                type="button"*/}
      {/*                data-bs-toggle="collapse"*/}
      {/*                data-bs-target="#tap1"*/}
      {/*                aria-expanded="false"*/}
      {/*                aria-controls="tap1"*/}
      {/*              >*/}
      {/*                <span class="color-main fw-bold">*/}
      {/*                  <i class="far fa-question-circle"> </i> Question title*/}
      {/*                  #1 A*/}
      {/*                </span>*/}
      {/*              </button>*/}
      {/*            </h2>*/}
      {/*            <div*/}
      {/*              id="tap1"*/}
      {/*              class="accordion-collapse collapse"*/}
      {/*              aria-labelledby="head1"*/}
      {/*              data-bs-parent="#ac1"*/}
      {/*            >*/}
      {/*              <div class="accordion-body">*/}
      {/*                body body body body body body body body*/}
      {/*              </div>*/}
      {/*              <div class="accordion-body">*/}
      {/*                body body body body body body body body*/}
      {/*              </div>*/}
      {/*              <div class="accordion-body">*/}
      {/*                body body body body body body body body*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div class="accordion-item">*/}
      {/*            <h2 class="accordion-header" id="head3">*/}
      {/*              <button*/}
      {/*                class="accordion-button collapsed"*/}
      {/*                type="button"*/}
      {/*                data-bs-toggle="collapse"*/}
      {/*                data-bs-target="#tap3"*/}
      {/*                aria-expanded="false"*/}
      {/*                aria-controls="tap3"*/}
      {/*              >*/}
      {/*                <span class="color-main fw-bold">*/}
      {/*                  <i class="far fa-question-circle"> </i> Question title*/}
      {/*                  #3 A*/}
      {/*                </span>*/}
      {/*              </button>*/}
      {/*            </h2>*/}
      {/*            <div*/}
      {/*              id="tap3"*/}
      {/*              class="accordion-collapse collapse"*/}
      {/*              aria-labelledby="head3"*/}
      {/*              data-bs-parent="#ac1"*/}
      {/*            >*/}
      {/*              <div class="accordion-body">*/}
      {/*                body body body body body body body body*/}
      {/*              </div>*/}
      {/*              <div class="accordion-body">*/}
      {/*                body body body body body body body body*/}
      {/*              </div>*/}
      {/*              <div class="accordion-body">*/}
      {/*                body body body body body body body body*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="section-info">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-12">
              <div className="single-info">
                <i className="fas fa-map"></i>
                <h3>Address</h3>
                <ul>
                  {/* <li>Online.</li> */}
                  <li style={{ margin: "-11px" }}>
                    60 Cannon Street, London, EC4N 6NP
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-12">
              <div className="single-info">
                <i className="fas fa-phone"></i>
                <h3>Call</h3>
                <ul>
                  {/* <li>
                    <a href="tel:+0000000000000000">+(+++) 0000 000 000</a>
                  </li> */}
                  <li>
                    <a href="tel:+441133281471">+44 113 328 1471</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-12">
              <div className="single-info">
                <i className="fas fa-envelope"></i>
                <h3>Mail</h3>
                <ul>
                  {/* <li>
                    <a href="mailto:test@test.com">test@test.com</a>
                  </li> */}
                  <li>
                    <a href="info@koinstrade.com">info@koinstrade.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { toggleSignUpPopUpStatus })(Pricing);
