import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "../assets/image/logo_new.png";
import { NavLink, Redirect } from "react-router-dom";
import { Logout } from "../actions/auth.actions";

class SideBar extends Component {
  _onLogout = () => {
    localStorage.clear();
    this.props.Logout();

    return <Redirect to="/" />;
  };
  render() {
    const { userRole } = this.props;
    const { user } = this.props;
    const { active_subscription } = user;
    return (
      <div className="sidenav">
        <div>
          <img src={logo} alt="" className="logo" />
        </div>
        <div className="userinfo">
          <h2>
            {user.first_name} {user.last_name}
          </h2>
        </div>
        <div>
          <div>
            {active_subscription ? (
              <>
                <NavLink to="/dashboard">Dashboard</NavLink>
                <NavLink to="/change-password">Change Password</NavLink>
                <NavLink to="/update-email">Change Email</NavLink>
              </>
            ) : null}
            {user.user_role !== "Admin" ? (
              <NavLink to="/payments">Payments</NavLink>
            ) : null}
            <a href="" onClick={() => this._onLogout()}>
              Logout
            </a>
          </div>
          <div></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { Logout })(SideBar);
