import React, { Component } from "react";
import { Provider } from "react-redux";
import store from "../src/config/store";
import AppRouter from "../src/config/router";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <AppRouter />
      </Provider>
    );
  }
}
