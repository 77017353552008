import React, { useState, useEffect } from "react";
import axios from "axios";
import { url } from "../../assets/constants";

export default function ContactUs() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");
  const [subject, setsubject] = useState("");
  const [errorStatus, seterrorStatus] = useState(null);
  const [statusMessage, setstatusMessage] = useState("");

  useEffect(() => {
    scrollToTop();
    return;
  }, []);

  const scrollToTop = () => {
    document
      .getElementsByClassName("parent-main")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const contactUs = () => {
    seterrorStatus(null);
    setstatusMessage("");
    if (name && email && subject && message) {
      if (validateEmail(email)) {
        let end_point = url + "api/v1/auth/contact-us";

        let formData = {
          name: name,
          email: email,
          subject: subject,
          message: message,
        };

        axios
          .post(end_point, formData)
          .then((res) => {
            if (res.data.status) {
              seterrorStatus(false);
              setstatusMessage(
                "Your request has been received by us. Our team will contact you shortly!"
              );
            } else {
              seterrorStatus(true);
              setstatusMessage(
                "Your request was not received by us. Please try again after some time."
              );
            }
            setname("");
            setemail("");
            setsubject("");
            setmessage("");
            setTimeout(() => {
              seterrorStatus(null);
              setstatusMessage("");
            }, 5000);
          })
          .catch((err) => {
            seterrorStatus(true);
            setstatusMessage(
              "Something went wrong. Please try again after some time."
            );
            setTimeout(() => {
              seterrorStatus(null);
              setstatusMessage("");
            }, 5000);
          });
      } else {
        seterrorStatus(true);
        setstatusMessage("Please enter a valid email id.");
      }
    } else {
      seterrorStatus(true);
      setstatusMessage("All fields are required");
    }
  };
  return (
    <>
      <div class="section-info">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-12 col-12">
              <div class="single-info">
                <i class="fas fa-map"></i>
                <h3>Address</h3>
                <ul>
                  {/* <li>Online.</li> */}
                  <li style={{ margin: "-11px" }}>
                    60 Cannon Street, London, EC4N 6NP
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="single-info">
                <i class="fas fa-phone"></i>
                <h3>Call</h3>
                <ul>
                  {/* <li>
                    <a href="tel:+0000000000000000">+(+++) 0000 000 000</a>
                  </li> */}
                  <li>
                    <a href="tel:+441133281471">+44 113 328 1471</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="single-info">
                <i class="fas fa-envelope"></i>
                <h3>Mail</h3>
                <ul>
                  {/* <li>
                    <a href="mailto:test@test.com">test@test.com</a>
                  </li> */}
                  <li>
                    <a href="info@koinstrade.com">info@koinstrade.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-map">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14368.32439828512!2d55.9683029!3d25.8008984!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd3b5ffc06b24ab09!2sRas%20Al%20Khaimah%20Economic%20Zone%20-%20RAKEZ!5e0!3m2!1sen!2seg!4v1630700781398!5m2!1sen!2seg"
                className="w-100"
                height="600"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <section class="section-form">
        <div class="container">
          <form
            action="forms/contact.php"
            method="post"
            role="form"
            class="php-email-form"
          >
            <div class="row">
              <div class="col-md-6 form-group">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  placeholder="Your Name"
                  required=""
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
              <div class="col-md-6 form-group">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                  required=""
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
            </div>
            <div class="form-group mt-3">
              <input
                type="text"
                class="form-control"
                name="subject"
                id="subject"
                placeholder="Subject"
                required=""
                value={subject}
                onChange={(e) => setsubject(e.target.value)}
              />
            </div>
            <div class="form-group mt-3">
              <textarea
                class="form-control"
                name="message"
                rows="5"
                placeholder="Message"
                required=""
                value={message}
                onChange={(e) => setmessage(e.target.value)}
              ></textarea>
            </div>
            <div class="text-right my-3">
              <button
                type="button"
                class="btn btn-danger btn-main"
                onClick={() => contactUs()}
              >
                SEND
              </button>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              {errorStatus === false ? (
                <div class="alert alert-success">{statusMessage}</div>
              ) : null}
              {errorStatus === true ? (
                <div class="alert alert-warning">{statusMessage}</div>
              ) : null}
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
