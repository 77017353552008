import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { compose } from "redux";

import { userSignIn, resetSignUpSignInStatus } from "../actions/auth.actions";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: "",
      // verification_status: null,
    };
  }

  componentDidMount() {
    // let params = new URLSearchParams(window.location.search);
    // let verification_status = params.get("verification_status");
    // verification_status === "true" &&
    //   this.setState({
    //     verification_status: true,
    //   });
    // verification_status === "false" &&
    //   this.setState({
    //     verification_status: false,
    //   });
    // setTimeout(() => {
    //   this.setState({
    //     verification_status: null,
    //   });
    // }, 10000);
  }

  _handleChange = (event) => {
    const { name, value } = event.target;
    if (this.props.signIn.error) {
      this.props.resetSignUpSignInStatus();
    }

    this.setState({
      [name]: value,
      error: null,
    });
  };

  _handleSubmit = () => {
    const { email, password, error } = this.state;
    this.setState({
      error: null,
    });
    if (email != "" && password != "" && !error) {
      this.props.userSignIn(email, password);
    } else {
      this.setState({
        error: "All field inputs are mandatory.",
      });
    }
  };

  _handleSignInSuccess = () => {
    this.props.setloginPopUp(false);
    return <Redirect to="/verify" />;
  };

  render() {
    const { email, password, error } = this.state;
    const { signIn, setloginPopUp, setsignUpPopUp, verification_status } =
      this.props;
    const { status, loader } = signIn;
    const error_response = signIn.error;
    return (
      <>
        {status ? this._handleSignInSuccess() : null}
        <div className="modal-body">
          <div className="row g-3">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                aria-label="email"
                value={email}
                name="email"
                onChange={this._handleChange}
              />
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                aria-label="password"
                value={password}
                name="password"
                onChange={this._handleChange}
              />
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="remember"
                />
                <label className="form-check-label" htmlFor="remember">
                  Remember Me
                </label>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={() => this._handleSubmit()}
              >
                Login
              </button>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div
                className={
                  error
                    ? "alert alert-warning"
                    : error_response
                    ? "alert alert-warning"
                    : verification_status === true
                    ? "alert alert-success"
                    : verification_status === false
                    ? "alert alert-danger"
                    : ""
                }
              >
                {error
                  ? error
                  : error_response
                  ? error_response
                  : verification_status === true
                  ? "Your account has been verified successfully!"
                  : verification_status === false
                  ? "Account verification failed. Please try again or contact support."
                  : null}
              </div>

              <p style={{ textAlign: "center" }}>
                Haven't Signed In Yet ?{" "}
                <Link
                  onClick={() => {
                    setloginPopUp(false);
                    setsignUpPopUp(true);
                  }}
                >
                  Sign Up
                </Link>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  signIn: state.auth.signIn,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { userSignIn, resetSignUpSignInStatus })
)(SignIn);
